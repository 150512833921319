import React, { useRef, useState, useEffect } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import Main from "../hoc/Main";
import MessageContent from "../components/MessageContent";
import ChannelContent from "../components/ChannelContent";
import { getImageURL, getServerURL } from "../helper/envConfig";
import { ValidateFields } from "../components/validate/ValidateFields";
import { errorResponse } from "../helper/error";
import ErrorFilter from "../helper/errorFilter";
import { toast } from "react-toastify";
import api from "../helper/api";
import SingleError from "../helper/SingleError";
const requireField = ["image", "name", "description"];

const Community = () => {
  const [addModal, setAddModal] = useState(false);
  const [state, setState] = useState({});
  const [submitCount, setSubmitCount] = useState(0);
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [search, setSearch] = useState("");
  const [temp, setTemp] = useState(false);
  const [searchTemp, setSearchTemp] = useState(false);

  const fileInputRef = useRef(null);
  const url = getImageURL();
  const serverURL = getServerURL();
  const [refreshData, setRefreshData] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));


  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => setSelectedImage(reader.result);
      reader.readAsDataURL(file);

      setState((prevState) => ({
        ...prevState,
        image: file,
      }));
    }
  };

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  // const handleSubmit = () => {
  //   setTemp(false);

  //   // Default to empty strings if state.name or state.description is undefined
  //   const trimmedName = (state.name || '').trim();
  //   const trimmedDescription = (state.description || '').trim();

  //   const updatedValues = { ...state, name: trimmedName, description: trimmedDescription };
  //   let validationErrors = ValidateFields(updatedValues);
  //   validationErrors = ErrorFilter(validationErrors, requireField);
  //   setErrors(validationErrors);

  //   if (Object.keys(validationErrors).length) {
  //     Object.entries(validationErrors).forEach(([key], i) => {
  //       if (i === 0)
  //         document.getElementById(key)?.scrollIntoView({ behavior: "smooth" });
  //     });
  //     return; // Stop submission if there are validation errors
  //   }

  //   setLoading(true);

  //   // Retrieve token from localStorage
  //   const token = localStorage.getItem('token');

  //   if (!token) {
  //     setLoading(false);
  //     toast.error("Authentication token is missing");
  //     return;
  //   }

  //   const formData = new FormData();
  //   formData.append('name', trimmedName);
  //   formData.append('description', trimmedDescription);
  //   if (state.image) {
  //     formData.append('image', state.image);
  //   }

  //   api
  //     .postWithToken(`${serverURL}admin-create-channels`, formData, token, {
  //       headers: {
  //         'Content-Type': 'multipart/form-data',
  //       },
  //     })
  //     .then(async (res) => {
  //       setLoading(false);
  //       if (res?.data?.success === true) {
  //         // Clear the form data and image
  //         setState({
  //           name: '',
  //           description: '',
  //           image: null,
  //         });
  //         setSelectedImage(null);

  //         setAddModal(false);
  //         setTemp(true);

  //         setRefreshData(!refreshData);
  //         toast.success(res?.data?.data?.message || "Channel created successfully");
  //       } else {
  //         toast.error(res?.data?.message || "Something went wrong");
  //       }
  //     })
  //     .catch((e) => {
  //       setLoading(false);
  //       errorResponse(e);
  //     });
  // };
  const handleSubmit = async () => {
    setTemp(false);
    const trimmedName = (state.name || '').trim();
    const trimmedDescription = (state.description || '').trim();
    const updatedValues = { ...state, name: trimmedName, description: trimmedDescription };

    // Add the maximum length validation for channel name
    const validationErrors = {};
    if (trimmedName.length > 50) {
      validationErrors.name = 'Channel name must be less than 50 characters';
    }

    // Run existing validations
    const additionalErrors = ValidateFields(updatedValues);
    // Merge additional validation errors with the new ones
    const allErrors = { ...validationErrors, ...additionalErrors };
    const filteredErrors = ErrorFilter(allErrors, requireField);
    setErrors(filteredErrors);

    // Check if there are any validation errors
    if (Object.keys(filteredErrors).length > 0) {
      Object.entries(filteredErrors).forEach(([key], i) => {
        if (i === 0)
          document.getElementById(key)?.scrollIntoView({ behavior: "smooth" });
      });
      setLoading(false); // Ensure loading state is turned off
      return;
    }

    setLoading(true);
    const token = localStorage.getItem('token');
    if (!token) {
      setLoading(false);
      toast.error("Authentication token is missing");
      return;
    }

    const existingChannels = await fetchData();
    if (!Array.isArray(existingChannels)) {
      setLoading(false);
      toast.error("Failed to fetch existing channels.");
      return;
    }

    const nameExists = existingChannels.some(channel => channel.name === trimmedName);
    if (nameExists) {
      setLoading(false);
      toast.error("Channel name already exists.");
      return;
    }

    const formData = new FormData();
    formData.append('name', trimmedName);
    formData.append('description', trimmedDescription);
    if (state.image) {
      formData.append('image', state.image);
    }

    api
      .postWithToken(`${serverURL}admin-create-channels`, formData, token, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(async (res) => {
        setLoading(false);
        if (res?.data?.success === true) {
          setState({
            name: '',
            description: '',
            image: null,
          });
          setSelectedImage(null);
          setAddModal(false);
          setTemp(true);
          setRefreshData(!refreshData);
          toast.success(res?.data?.data?.message || "Channel created successfully");
        } else {
          toast.error(res?.data?.message || "Something went wrong");
        }
      })
      .catch((e) => {
        setLoading(false);
        errorResponse(e);
      });
  };


  const [data, setData] = useState([])
  const fetchData = async () => {
    try {
      const response = await api.getWithToken(`${serverURL}admin-list-channels`);
      if (response?.data?.success) {
        console.log("url>>",serverURL);

        return response?.data?.data?.records || [];

      }

    } catch (error) {
      console.error("Error fetching existing channels:", error);
      return []; // Ensure it returns an empty array on error
    }
    return []; // Ensure it returns an empty array if response fails
  };

  useEffect(() => {
    fetchData();
  }, [refreshData]);
  return (
    <>
      <Main name="Community">
        <div className="communityPage d-sm-block d-md-flex d-lg-flex justify-content-between mt-3">
          <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className="nav-link active"
                id="pills-channels-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-channels"
                type="button"
                role="tab"
                aria-controls="pills-channels"
                aria-selected="true"
              >
                Channels
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="pills-message-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-message"
                type="button"
                role="tab"
                aria-controls="pills-message"
                aria-selected="false"
              >
                Messages
              </button>
            </li>
          </ul>


          <div
            onClick={() => setAddModal(true)}
            className="cursor-pointer"
          >
            <img
              src="/images/svg/add.svg"
              className="add-btn"
              alt="Add"
            />
          </div>

        </div>
        <div className="tab-content mt-2" id="pills-tabContent">
          <div
            className="tab-pane fade show active"
            id="pills-channels"
            role="tabpanel"
            aria-labelledby="pills-channels-tab"
          >
            <ChannelContent refresh={refreshData} temp={temp} search={search} searchTemp={searchTemp} setSearch={setSearch} setSearchTemp={setSearchTemp} />
          </div>
          <div
            className="tab-pane fade"
            id="pills-message"
            role="tabpanel"
            aria-labelledby="pills-message-tab"
          >
            <MessageContent refresh={refreshData} temp={temp} search={search} searchTemp={searchTemp} setSearch={setSearch} setSearchTemp={setSearchTemp} />
          </div>
        </div>
      </Main>

      {/* Add modal */}
      <Modal
        size="lg"
        show={addModal}
        onHide={() => setAddModal(false)}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">Add Channel</Modal.Title>
          <div className="d-flex justify-content-center text-center">
            <img src="/images/svg/line.svg" className="mt-2" alt="Divider" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-md-4 border-0 bg-transparent pt-0 ctmInput">
          <Row>
            <div className="position-relative my-4">
              <div className="d-flex justify-content-center align-items-center m-auto profileImage">
                <img
                  src={
                    selectedImage
                      ? selectedImage
                      : state?.image
                        ? url + state?.image
                        : "/images/png/duser.png"
                  }
                  onClick={handleImageClick}
                  className={`cursor-pointer ${selectedImage || state?.image ? "editprofileImage" : "profileImageSize"}`}
                  alt="Profile"
                />
              </div>
              <input
                type="file"
                accept="image/*"
                name="image"
                style={{ display: "none" }}
                ref={fileInputRef}
                onChange={handleFileChange}
              />
              <img
                onClick={handleImageClick}
                src="/images/svg/camera.svg"
                className="cursor-pointer position-absolute top-50 start-50 translate-middle"
                alt="Camera Icon"
              />
              {errors?.image && (
                <div className="d-flex justify-content-center">
                  <SingleError error={errors.image} />
                </div>
              )}
            </div>
          </Row>
          <Row>
            <Col md={12}>
              <label>Name</label>
              <input
                className="form-control px-3 mb-3"
                placeholder="Name"
                type="text"
                name="name"
                value={state.name || ''}
                onChange={handleChange}
              />

              <SingleError error={errors.name} />
            </Col>
            <Col md={12}>
              <label>Description</label>
              <textarea
                rows={3}
                className="form-control px-3 mb-3"
                placeholder="Description"
                name="description"
                value={state.description || ''}
                onChange={handleChange}
              />
              <SingleError error={errors.description} />
            </Col>
          </Row>
          <div className="d-flex justify-content-center">
            <button
              className={`btn-login bg-main text-uppercase border-0 w-75 my-4 ${Boolean(loading) && "btnDisable"
                }`}
              disabled={Boolean(loading)}
              onClick={() => {
                handleSubmit();
                setSubmitCount(1);
              }}
            >
              Save
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Community;
