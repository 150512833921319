import React, { useState,useEffect } from "react";
import Main from "../hoc/Main";
import { Col, Modal, Row } from "react-bootstrap";
import SingleError from "../helper/SingleError";
import { ValidateFields } from "../components/validate/ValidateFields";
import ErrorFilter from "../helper/errorFilter";
import { getServerURL } from "../helper/envConfig";
import { toast } from "react-toastify";
import { errorResponse } from "../helper/error";
import api from "../helper/api";
import HelpAndSupport from "../components/setting-pages/HelpAndSupport";
import TermsAndConditions from "../components/setting-pages/TermsAndConditions";
import Faqs from "../components/setting-pages/Faqs";
const faqRequireField = ["title", "description"];
let activeRequire;

const Settings = () => {
    const serverURL = getServerURL();

    const [helpSupportModal, setHelpSupportModal] = useState(false);

    const [state, setState] = useState({});
    const [submitCount, setSubmitCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [mainLoader, setMainLoader] = useState(true);
    const [activeTab, setActiveTab] = useState("helpSupport");
    const [countHelpSupport, setCountHelpSupport] = useState(1);
    const [countTerms, setCountTerms] = useState(1);
    const [refreshData, setRefreshData] = useState(false);
    const [search, setSearch] = useState("");
    const [temp, setTemp] = useState(false);
    const [searchTemp, setSearchTemp] = useState(false);
    const handleTabChange = (tabName) => {
        setState({});
        setActiveTab(tabName);
    };

    const addModalOpenFun = () => {
        setSubmitCount(0);
        setErrors({});
        setState({});
        if (activeTab === "faqs") {
            setHelpSupportModal(true);
            activeRequire = faqRequireField;
        }
    };

    const addModalCloseFun = () => {
        setSubmitCount(0);
        setErrors({});
        setState({});
        if (activeTab === "faqs") {
            setHelpSupportModal(false);
        }
    };

    const handleChange = (e) => {
        setErrors({});
        const { name, value } = e.target;
        if (submitCount > 0) {
            let validationErrors = ValidateFields({ ...state, [name]: value });
            validationErrors = ErrorFilter(validationErrors, activeRequire);
            setErrors(validationErrors);

            if (Object.keys(validationErrors).length === 0) {
                delete errors[name];
            }
        }

        if (name === "image") {
            setState((prevValues) => ({
                ...prevValues,
                image: e.target.files[0],
            }));
        } else {
            setState((prevValues) => ({
                ...prevValues,
                [name]: value,
            }));
        }

        if (state?.address?.contact) {
            if (!/^\+?\d+$/.test(state?.address?.contact)) {
                setErrors((prevValues) => ({
                    ...prevValues,
                    contact: "Please valid contact number",
                }));
            }
        }
    };

    const appendObjectToFormData = (obj, formData, prefix = "") => {
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                const propName = prefix ? `${prefix}[${key}]` : key;
                if (typeof obj[key] === "object") {
                    appendObjectToFormData(obj[key], formData, propName);
                } else {
                    formData.append(propName, obj[key]);
                }
            }
        }
    };
    const [faqList, setFaqList] = useState([]);
    useEffect(() => {
        getFaqList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getFaqList = () => {
        try {
            api.postWithToken(`${serverURL}faq-page-manage`, {
                action: "faq-list",
            })
                .then(async (res) => {
                    setLoading(false);
                    if (res?.data?.success === true) {
                        setFaqList(res.data.data);
                    }
                })
                .catch((e) => {
                    setLoading(false);
                });
        } catch (error) {
            setLoading(false);
        }
    };
    const addFaq = () => {
        // Trim whitespace from all string fields in the state
        const updatedState = { ...state };
        Object.keys(updatedState).forEach((key) => {
            if (typeof updatedState[key] === "string") {
                updatedState[key] = updatedState[key].trim();
            }
        });
    
        // Validate fields after trimming
        let validationErrors = ValidateFields(updatedState);
        validationErrors = ErrorFilter(validationErrors, faqRequireField);
        setErrors(validationErrors);
    
        if (Object.keys(validationErrors)?.length) {
            // eslint-disable-next-line array-callback-return
            Object.entries(validationErrors)?.map(([key], i) => {
                if (i === 0)
                    document
                        .getElementById(key)
                        ?.scrollIntoView({ behavior: "smooth" });
            });
        }
    
        if (
            Object.keys(validationErrors).length === 0 &&
            Object.keys(errors).length === 0
        ) {
            setLoading(true);
            const formData = new FormData();
            formData.append("action", "faq-create");
    
            // Append data to FormData (after trimming)
            for (const key in updatedState) {
                if (updatedState.hasOwnProperty(key)) {
                    if (typeof updatedState[key] === "object") {
                        appendObjectToFormData(updatedState[key], formData, key);
                    } else {
                        formData.append(key, updatedState[key]);
                    }
                }
            }
    
            try {
                api.postWithToken(`${serverURL}faq-page-manage`, formData)
                    .then(async (res) => {
                        setLoading(false);
                        if (res?.data?.success === true) {
                            addModalCloseFun();
                            getFaqList();
                            setState({}); // Reset state after success
                            if (typeof res?.data?.message === "string")
                                toast.success(res?.data?.message);
                            else
                                toast.success("Faq entry created successfully");
                        }
                    })
                    .catch((e) => {
                        setLoading(false);
                        errorResponse(e);
                        if (e?.response?.data?.status === 0) {
                            addModalCloseFun();
                        }
                    });
            } catch (error) {
                setLoading(false);
            }
        }
    };
    

      
    return (
        <>
            <Main name="Settings">
                <div className="communityPage d-sm-block d-md-flex d-lg-flex justify-content-between mt-3">
                    <ul className="nav nav-pills" id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button
                                className="nav-link active"
                                id="pills-home-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-home"
                                type="button"
                                role="tab"
                                aria-controls="pills-home"
                                aria-selected="true"
                                onClick={() => handleTabChange("helpSupport")}
                            >
                                Help & Support
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button
                                className="nav-link"
                                id="pills-terms-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-terms"
                                type="button"
                                role="tab"
                                aria-controls="pills-terms"
                                aria-selected="false"
                                onClick={() => handleTabChange("terms")}
                            >
                                Terms & Conditions
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button
                                className="nav-link"
                                id="pills-faqs-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#pills-faqs"
                                type="button"
                                role="tab"
                                aria-controls="pills-faqs"
                                aria-selected="false"
                                onClick={() => handleTabChange("faqs")}
                            >
                                FAQs
                            </button>
                        </li>
                    </ul>

                    <Col md={4} lg={4} xl={4} sm={12} className="mt-3 mt-md-0">
                        <div className="d-flex align-items-center gap-3 justify-content-end">
                            {(Boolean(
                                activeTab === "helpSupport" &&
                                    countHelpSupport === 0
                            ) ||
                                Boolean(
                                    activeTab === "terms" && countTerms === 0
                                ) ||
                                Boolean(activeTab === "faqs")) && (
                                <div
                                    onClick={() => addModalOpenFun()}
                                    className="cursor-pointer"
                                >
                                    <img
                                        src="/images/svg/add.svg"
                                        className="add-btn"
                                        alt=""
                                    />
                                </div>
                            )}
                        </div>
                    </Col>
                    <Col md={4} lg={4} xl={4} className="mt-3 mt-md-0">
            <div className="d-flex align-items-center gap-3">
              <div className="form-control d-flex align-items-center px-3">
                <svg width="17" height="17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M13.602 12.4131C14.0573 12.8601 14.5206 13.3151 14.9759 13.7781C15.5271 14.3289 16.0863 14.8797 16.6374 15.4384C17.0848 15.8854 17.1167 16.4043 16.7413 16.7635C16.3818 17.1147 15.8706 17.0748 15.4392 16.6437C14.5286 15.7417 13.6179 14.8318 12.7153 13.9138C12.6115 13.81 12.5396 13.6743 12.4677 13.5706C8.62541 16.516 3.84854 15.2947 1.54798 12.2455C-0.77655 9.15638 -0.457028 4.84597 2.33081 2.13999C5.10267 -0.54205 9.44019 -0.725642 12.4597 1.71693C15.4392 4.13555 16.3978 8.75727 13.602 12.4131ZM13.562 7.63975C13.57 4.34309 10.9419 1.70096 7.64287 1.69298C4.35179 1.685 1.69176 4.32712 1.69176 7.61581C1.68377 10.9045 4.32782 13.5546 7.61891 13.5626C10.918 13.5706 13.554 10.9444 13.562 7.63975Z"
                    fill="black"
                  />
                </svg>
                <input
                  className="form-control border-0 shadow-none pl-black"
                  placeholder="Search by title"
                  type="text"
                  name="search"
                  autoComplete="off"
                  onChange={(e) => {
                    setSearch(e?.target?.value);
                    setSearchTemp(true);
                  }}
                />
              </div>
            </div>
          </Col>
                </div>
                <div className="tab-content mt-2" id="pills-tabContent">
                    <div
                        className="tab-pane fade show active"
                        id="pills-home"
                        role="tabpanel"
                        aria-labelledby="pills-home-tab"
                    >
                        {Boolean(activeTab === "helpSupport") && (
                            <HelpAndSupport
                                countHelpSupport={setCountHelpSupport} refresh={refreshData} temp={temp} search={search} searchTemp={searchTemp}
                            />
                        )}
                    </div>
                    <div
                        className="tab-pane fade"
                        id="pills-terms"
                        role="tabpanel"
                        aria-labelledby="pills-terms-tab"
                    >
                        {Boolean(activeTab === "terms") && (
                            <TermsAndConditions setCountAbout={setCountTerms} />
                        )}
                    </div>
                    <div
                        className="tab-pane fade show "
                        id="pills-faqs"
                        role="tabpanel"
                        aria-labelledby="pills-faqs-tab"
                    >
                        {Boolean(activeTab === "faqs") && <Faqs faqList={faqList} setFaqList={setFaqList} />}
                    </div>
                </div>
            </Main>

            {/* Add Faq Modal */}
            <Modal
                size="lg"
                show={helpSupportModal}
                onHide={() => addModalCloseFun()}
                centered
                className="modal-main info-modal"
            >
                <Modal.Header
                    className="d-block position-relative border-0 pt-4 px-4"
                    closeButton
                >
                    <Modal.Title className="text-center">Add FAQ</Modal.Title>
                    <div className="d-flex justify-content-center text-center">
                        <img
                            src="/images/svg/line.svg"
                            className="mt-2"
                            alt=""
                        />
                    </div>
                </Modal.Header>
                <Modal.Body className="mx-md-4 border-0 bg-transparent pt-0 ctmInput">
                    <Row>
                        <Col md={12}>
                            <label id="title">
                                Title <span className="star">*</span>
                            </label>
                            <input
                                className="form-control px-3"
                                placeholder="Enter title here"
                                type="text"
                                name="title"
                                value={state?.title || ""}
                                onChange={handleChange}
                            />
                            <SingleError error={errors?.title} />
                        </Col>
                        <Col md={12}>
                            <label id="description">
                                Description <span className="star">*</span>
                            </label>
                            <textarea
                                rows={3}
                                className="form-control px-3"
                                placeholder="Enter description here"
                                type="text"
                                name="description"
                                value={state?.description || ""}
                                onChange={handleChange}
                            />
                            <SingleError error={errors?.description} />
                        </Col>
                        {/* <Col
                            xs={12}
                            lg={12}
                            xl={12}
                            className="pt-2 cst-card"
                            id="image"
                        >
                            {Boolean(state?.image) && (
                                <div className="preview-images mb-4 p-3 gap-3">
                                    <div className="home-preview">
                                        <img
                                            src={URL.createObjectURL(
                                                state?.image
                                            )}
                                            alt=""
                                        />
                                        <button
                                            onClick={() =>
                                                setState((prevValues) => ({
                                                    ...prevValues,
                                                    image: "",
                                                }))
                                            }
                                            className="d-flex justify-content-center"
                                        >
                                            <img
                                                src="/images/svg/closeImg.svg"
                                                style={{
                                                    width: "8px",
                                                    height: "16px",
                                                }}
                                                alt=""
                                            />
                                        </button>
                                    </div>
                                </div>
                            )}
                            <div className="upload__btn d-flex align-items-center justify-content-center py-2">
                                <label className="d-flex align-items-center gap-1">
                                    {"Upload Image"}
                                    <input
                                        type="file"
                                        className="upload_inputfile"
                                        name="image"
                                        onChange={handleChange}
                                    />
                                </label>
                            </div>

                            {Boolean(errors?.image) && (
                                <SingleError error={errors?.image} mb={false} />
                            )}
                        </Col> */}
                    </Row>

                    <div className="d-flex justify-content-center mt-3">
                        <button
                            className={`btn-login bg-main text-uppercase border-0 w-75 my-4 ${
                                Boolean(loading) && "btnDisable"
                            }`}
                            disabled={Boolean(loading)}
                            onClick={() => {
                                addFaq();
                                setSubmitCount(1);
                            }}
                        >
                            {Boolean(loading) ? "Loading..." : "Add"}
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Settings;
