import React, { useEffect, useState } from "react";
import Loader from "../loader/Loader";
import { getServerURL } from "../../helper/envConfig";
import api from "../../helper/api";
import SingleError from "../../helper/SingleError";
import { Col, Modal, Row } from "react-bootstrap";
import { errorResponse } from "../../helper/error";
import { toast } from "react-toastify";

const TermsAndConditions = ({ countHelpSupport }) => {
    const serverURL = getServerURL();
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [mainLoader, setMainLoader] = useState(false);
    const [editModel, setEditModel] = useState(false);

    const [termsData, setTermsData] = useState({});
    const [editTermsData, setEditTermsData] = useState([]);

    const editModelOpenFun = (terms) => {
        setErrors({});
        setEditTermsData(terms);
        setEditModel(true);
    };

    const editModelCloseFun = () => setEditModel(false);

    useEffect(() => {
        getTermsData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getTermsData = () => {
        try {
            api.postWithToken(`${serverURL}terms-page-manage`, {
                action: "terms-list",
            })
                .then(async (res) => {
                    setMainLoader(false);
                    if (res?.data?.success === true) {
                        setTermsData(res.data.data);
                        countHelpSupport(1);
                    }
                })
                .catch((e) => {
                    setMainLoader(false);
                    if (
                        e?.response?.status === 404 ||
                        e?.response?.statusText === "Not Found"
                    ) {
                        setTermsData([]);
                    }
                });
        } catch (error) {
            setMainLoader(false);
        }
    };

    const addNewTerms = () => {
        const newData = [...editTermsData];
        newData.push({ title: "", description: [""] });
        setEditTermsData(newData);
    };

    const addSubDescription = (index) => {
        const newData = [...editTermsData];
        newData[index].description.push("");
        setEditTermsData(newData);
    };

    const deleteSubDescription = (mainIndex, index) => {
        const newData = [...editTermsData];
        newData[mainIndex].description.splice(index, 1);
        setEditTermsData(newData);
    };

    const deleteSingleTerm = (i) => {
        const newData = [...editTermsData];
        newData.splice(i, 1);
        setEditTermsData(newData);
    };

    const onDataChange = (e, mainIndex, index) => {
        const { name, value } = e.target;

        const newData = [...editTermsData];

        if (name === "title") {
            newData[mainIndex].title = value;
            setEditTermsData(newData);
            return;
        }
        if (name === "description") {
            newData[mainIndex].description[index] = value;
            setEditTermsData(newData);
            return;
        }
    };

    const handleSave = () => {
        try {
            const trimmedData = editTermsData.map(term => ({
                title: term.title.trim(),
                description: term.description.map(desc => (
                    desc.trim()
                ))
            }))
            api.postWithToken(`${serverURL}terms-page-manage`, {
                terms: trimmedData,
                action: "terms-update",
            })
                .then(async (res) => {
                    setLoading(false);
                    if (res?.data?.success === true) {
                        editModelCloseFun();
                        getTermsData();
                        setEditTermsData([]);
                        if (typeof res?.data?.message === "string") {
                            toast.success(res?.data?.message);
                        } else {
                            toast.success(
                                "Terms & condition updated successfully!"
                            );
                        }
                    }
                })
                .catch((e) => {
                    setLoading(false);
                    errorResponse(e);
                });
        } catch (error) {
            setLoading(false);
        }
    };

    return (
        <>
            {Boolean(mainLoader) ? (
                <Loader />
            ) : termsData?.terms?.length > 0 ? (
                <div className="card p-3 my-3 term-condition">
                    <div className="d-flex justify-content-between align-items-center gap-2 border-bottom pb-3 mb-4">
                        <h5 className="explore-head col-10">
                            Terms & Condition{" "}
                        </h5>
                        <button
                            className="border-0 bg-transparent"
                            onClick={() => {
                                editModelOpenFun(termsData.terms);
                            }}
                        >
                            <img
                                src="/images/svg/edit.svg"
                                title="Edit"
                                alt=""
                            />
                        </button>
                    </div>
                    <div>
                        {termsData?.terms.map((term, i) => {
                            return (
                                <>
                                    <div className="term-details mb-2" key={i}>
                                        <h6>
                                            {i + 1} {term.title}
                                        </h6>
                                        {term.description.map((desc, index) => {
                                            return (
                                                <>
                                                    <p>
                                                        {i + 1}.{index + 1}{" "}
                                                        {desc}
                                                    </p>
                                                </>
                                            );
                                        })}
                                    </div>
                                </>
                            );
                        })}
                    </div>
                </div>
            ) : (
                <h4 className="text-center p-5">No Records Founds.</h4>
            )}

            {/* Edit modal */}
            <Modal
                size="xl"
                show={editModel}
                onHide={editModelCloseFun}
                centered
                className="modal-main info-modal term-condition-modal"
            >
                <Modal.Header
                    className="d-block position-relative border-0 pt-4 px-4"
                    closeButton
                >
                    <Modal.Title className="text-center">
                        Edit Terms & Conditions
                    </Modal.Title>
                    <div className="d-flex justify-content-center text-center mt-2">
                        <img src="/images/svg/line.svg" alt="" />
                    </div>
                </Modal.Header>
                <Modal.Body className="mx-md-4 border-0 bg-transparent pt-0 ctmInput">
                    <Row>
                        {editTermsData && editTermsData?.length > 0 ? (
                            <>
                                {editTermsData.map((terms, i) => {
                                    return (
                                        <>
                                            <Col md={12}>
                                                <div className="d-flex gap-3 mt-2">
                                                    <label className="mt-4">
                                                        Title {i + 1}
                                                    </label>
                                                </div>
                                                <div className="d-flex gap-2 align-items-center">
                                                    <input
                                                        className="form-control px-3"
                                                        placeholder="Enter title here"
                                                        type="text"
                                                        name="title"
                                                        value={terms.title}
                                                        onChange={(e) =>
                                                            onDataChange(e, i)
                                                        }
                                                    // onKeyPress={handleKeyPress}
                                                    />

                                                    <button
                                                        className="border-0 bg-transparent"
                                                        onClick={() =>
                                                            deleteSingleTerm(i)
                                                        }
                                                    >
                                                        <img
                                                            src="/images/svg/delete.svg"
                                                            title="Delete"
                                                            alt=""
                                                        />
                                                    </button>
                                                </div>
                                                <button
                                                    className="bg-main border-0 mt-4 mb-2"
                                                    onClick={() =>
                                                        addSubDescription(i)
                                                    }
                                                >
                                                    + Add Sub Description
                                                </button>
                                                {terms.description.map(
                                                    (desc, index) => {
                                                        return (
                                                            <div key={index}>
                                                                <label>
                                                                    Sub
                                                                    description{" "}
                                                                    {i + 1}.
                                                                    {index + 1}
                                                                </label>
                                                                <div className="d-flex gap-2 align-items-center">
                                                                    <textarea
                                                                        className="form-control px-3"
                                                                        placeholder="Enter description here"
                                                                        name="description"
                                                                        rows={3}
                                                                        value={
                                                                            desc
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            onDataChange(
                                                                                e,
                                                                                i,
                                                                                index
                                                                            )
                                                                        }
                                                                    />
                                                                    {index !==
                                                                        0 && (
                                                                            <button
                                                                                className="border-0 bg-transparent"
                                                                                onClick={() =>
                                                                                    deleteSubDescription(
                                                                                        i,
                                                                                        index
                                                                                    )
                                                                                }
                                                                            >
                                                                                <img
                                                                                    src="/images/svg/delete.svg"
                                                                                    title="Delete"
                                                                                    alt=""
                                                                                />
                                                                            </button>
                                                                        )}
                                                                </div>
                                                            </div>
                                                        );
                                                    }
                                                )}

                                                <SingleError
                                                    error={errors?.title}
                                                />
                                            </Col>
                                        </>
                                    );
                                })}
                                <div className="d-flex justify-content-center">
                                    <button
                                        className="add-term"
                                        onClick={addNewTerms}
                                    >
                                        Add New terms
                                    </button>
                                </div>
                            </>
                        ) : (
                            <></>
                        )}
                    </Row>
                    <div className="d-flex justify-content-center ">
                        <button
                            className={`btn-login bg-main text-uppercase border-0 w-50 my-4
              ${Boolean(loading) && "btnDisable"}`}
                            disabled={Boolean(loading)}
                            onClick={() => {
                                handleSave();
                            }}
                        >
                            {Boolean(loading) ? "Loading..." : "Save"}
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default TermsAndConditions;
