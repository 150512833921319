const formatTime = (time) => {

  const dateObj = new Date(time);
  
  // Get hours and minutes
  const hours = ('0' + dateObj.getUTCHours()).slice(-2);
  const minutes = ('0' + dateObj.getUTCMinutes()).slice(-2);
  
  // Formatted time string
  const formattedTime = `${hours}:${minutes}`;
    return time ? formattedTime : "";
  };

  export default formatTime;

