import React, { useEffect, useRef, useState } from "react";
import TablePagination from "./TablePagination";
import { Col, Modal, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { errorResponse } from "../helper/error";
import api from "../helper/api";
import { getImageURL, getServerURL } from "../helper/envConfig";
import { ValidateFields } from "./validate/ValidateFields";
import ErrorFilter from "../helper/errorFilter";
import { toast } from "react-toastify";
import Loader from "./loader/Loader";
import SingleError from "../helper/SingleError";
import { useDispatch } from "react-redux";
import { setLectureIndex } from "../redux/action/IndexAction";
const requireField = ["title", "description"];

const LectureList = ({ temp, search, searchTemp }) => {
  const location = useLocation();
  const { state } = location;

  const navigate = useNavigate();
  const serverURL = getServerURL();
  const url = getImageURL();
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();

  const [editModel, setEditModel] = useState(false);
  const [deleteModel, setDeleteModel] = useState(false);

  const editModelOpenFun = () => {
    setSelectedImage(null);
    setErrors({});
    setSubmitCount(0);
    setEditModel(true);
  };
  const editModelCloseFun = () => setEditModel(false);

  const deleteModelOpenFun = () => setDeleteModel(true);
  const deleteModelCloseFun = () => setDeleteModel(false);

  const [deleteDataID, setDeleteDataID] = useState("");

  const [page, setPage] = useState(state?.lecturePage || 1);
  const [limit, setLimit] = useState(8);

  const [lectureList, setLectureList] = useState([]);
  const [paginationData, setPaginationData] = useState({});
  const [editLectureData, setEditLectureData] = useState({});
  const [submitCount, setSubmitCount] = useState(0);
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [mainLoader, setMainLoader] = useState(true);

  useEffect(() => {
    getLectureList();
    getLectureAllList();
  }, []);

  useEffect(() => {
    getLectureList();
    getLectureAllList();
  }, [page]);

  useEffect(() => {
    if (temp || Boolean(search)) {
      getLectureList();
      getLectureAllList();
    }
    if (searchTemp && !search) {
      getLectureList();
      getLectureAllList();
    }
  }, [temp, search]);

  useEffect(() => {
    if (search?.length) setPage(1);
  }, [search]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (submitCount > 0) {
      let validationErrors = ValidateFields({
        ...editLectureData,
        [name]: value,
      });
      validationErrors = ErrorFilter(validationErrors, requireField);
      setErrors(validationErrors);
      if (Object.keys(validationErrors).length === 0) {
        delete errors[name];
      }
    }
    setEditLectureData((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const { name, value } = e.target;
    if (submitCount > 0) {
      let validationErrors = ValidateFields({
        ...editLectureData,
        [name]: value,
      });
      validationErrors = ErrorFilter(validationErrors, requireField);
      setErrors(validationErrors);

      if (Object.keys(validationErrors)?.length === 0) {
        delete errors[name];
      }
    }
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
      setEditLectureData({
        ...editLectureData,
        [e.target.name]: e.target.files[0],
      });
    }
  };

  const getLectureList = () => {
    try {
      api
        .postWithToken(
          `${serverURL}lecture-manage?page=${page}&limit=${limit}&search=${search}`,
          { action: "list" }
        )
        .then(async (res) => {
          setMainLoader(false);
          if (res?.data?.success === true) {
            setLectureList(
              res?.data?.data?.sort(
                (a, b) => a?.lecture_index - b?.lecture_index
              )
            );
            setPaginationData(res?.data?.pagination);
          }
        })
        .catch((e) => {
          setMainLoader(false);
          if (
            e?.response?.status === 404 ||
            e?.response?.statusText === "Not Found"
          ) {
            setLectureList([]);
            if (Boolean(page != 1)) {
              setPage(page - 1);
            }
          }
        });
    } catch (error) {
      setMainLoader(false);
    }
  };

  const getLectureAllList = () => {
    try {
      api
        .postWithToken(`${serverURL}lecture-manage`, { action: "list" })
        .then(async (res) => {
          if (res?.data?.success === true) {
            dispatch(setLectureIndex(res?.data?.data));
          }
        })
        .catch((e) => {
          if (
            e?.response?.status === 404 ||
            e?.response?.statusText === "Not Found"
          ) {
            dispatch(setLectureIndex([]));
          }
        });
    } catch (error) {
    }
  };

  const deleteLecture = () => {
    try {
      api
        .postWithToken(
          `${serverURL}lecture-manage?page=${page}&limit=${limit}&search=${search}`,
          { action: "delete", lecture_id: deleteDataID }
        )
        .then(async (res) => {
          setDeleteDataID("");
          getLectureList();
          getLectureAllList();
          if (lectureList?.length == 1) {
            setLectureList([]);
            dispatch(setLectureIndex([]));
          }
          deleteModelCloseFun();
          if (res?.data?.success === true) {
            if (typeof res?.data?.message === "string")
              toast.success(res?.data?.message);
            else toast?.success("Lecture deleted successfully");
          }
        })
        .catch((e) => {
          errorResponse(e);
        });
    } catch (error) { }
  };

  const editLecture = () => {
    // Create a copy of editLectureData and trim spaces from title and description
    const updatedValues = { ...editLectureData };
    updatedValues.title = updatedValues.title?.trim();
    updatedValues.description = updatedValues.description?.trim();

    // Validate fields
    let validationErrors = ValidateFields(updatedValues);
    validationErrors = ErrorFilter(validationErrors, requireField);
    setErrors(validationErrors);

    if (Object.keys(validationErrors)?.length) {
      Object.entries(validationErrors)?.map(([key], i) => {
        if (i === 0) {
          document.getElementById(key)?.scrollIntoView({ behavior: "smooth" });
        }
      });
    }

    if (Object.keys(validationErrors).length === 0) {
      setLoading(true);
      const formData = new FormData();
      formData.append("learn_id", updatedValues?._id);
      formData.append("action", "update");
      formData.append("title", updatedValues?.title);
      formData.append("description", updatedValues?.description);
      formData.append("lecture_index", updatedValues?.lecture_index);
      formData.append("lecture_image", updatedValues?.lecture_image);

      try {
        api
          .postWithToken(
            `${serverURL}lecture-manage?page=${page}&limit=${limit}&search=${search}`,
            formData
          )
          .then(async (res) => {
            setLoading(false);
            if (res?.data?.success === true) {
              getLectureList();
              getLectureAllList();
              editModelCloseFun();
              setSelectedImage("");
              if (typeof res?.data?.message === "string") {
                toast.success(res?.data?.message);
              } else {
                toast.success("Lecture Updated successfully");
              }
            }
          })
          .catch((e) => {
            setLoading(false);
            errorResponse(e);
          });
      } catch (error) {
        setLoading(false);
      }
    }
  };


  function handleNavigate(id, item) {
    navigate("/week-management", {
      state: { lectureList, id, lectureData: item, page: page },
    });
  }

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      setSubmitCount(1);
      editLecture();
    }
  };

  return (
    <>
      {Boolean(mainLoader) ? (
        <Loader />
      ) : Boolean(lectureList?.length) ? (
        <>
          <div className="card mt-3 px-4 pb-4 pt-2">
            <div className="row">
              {Array.isArray(lectureList) &&
                Boolean(lectureList?.length) &&
                lectureList?.map((item, index) => (
                  <div
                    className="col-md-6 col-sm-6 col-lg-6 col-xl-4 col-xxl-3 mt-3"
                    key={index + "lecture"}
                    onClick={() => handleNavigate(item?._id, item)}
                  >
                    <div className="card lectureView border-0">
                      <img
                        src={url + item?.lecture_image}
                        alt=""
                        height={"345.512px"}
                      />
                      <div className="lecture-box">
                        <div className="lectureBtn">
                          <div className="w-100">
                            <div className="lectureText">{item?.title}</div>
                            <div className="d-flex justify-content-between">
                              <div className="lectureDescription text-truncate pb-0">
                                {item?.description}
                              </div>
                              <div className="lectureIcon ">
                                <img
                                  title="Edit"
                                  src="/images/svg/editWhite.svg"
                                  className="pr-2 cursor-pointer"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    editModelOpenFun();
                                    setEditLectureData(item);
                                  }}
                                />
                                <img
                                  title="Delete"
                                  src="/images/svg/deleteWhite.svg"
                                  className="px-2 cursor-pointer"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    deleteModelOpenFun(item?._id);
                                    setDeleteDataID(item?._id);
                                  }}
                                />
                                <img
                                  title="Add"
                                  src="/images/svg/addWhite.svg"
                                  className="pl-2 cursor-pointer"
                                  onClick={() =>
                                    handleNavigate(item?._id, item)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div className="d-md-flex justify-content-end align-items-center mt-4 mb-4">
            {Boolean(lectureList?.length) && paginationData?.totalPages > 1 && (
              <TablePagination
                currentPage={paginationData?.currentPage}
                totalPages={paginationData?.totalPages}
                onPageChange={(newPage) => {
                  setPage(newPage);
                }}
              />
            )}
          </div>
        </>
      ) : (
        <div className="mt-5 w-100 p-5 d-flex justify-content-center">
          <h4 className="test-center">No Records Found.</h4>
        </div>
      )}

      {/* Edit user modal */}
      <Modal
        size="lg"
        show={editModel}
        onHide={editModelCloseFun}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">Edit Lecture</Modal.Title>
          <div className="d-flex justify-content-center text-center mt-2">
            <img src="/images/svg/line.svg" className="mt-2" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-md-4 border-0 bg-transparent pt-0 ctmInput">
          <Row className="my-4">
            <div className="position-relative" id="lecture_image">
              <div className="d-flex justify-content-center align-items-center m-auto profileImage">
                <img
                  src={
                    editLectureData?.lecture_image
                      ? typeof editLectureData?.lecture_image === "string"
                        ? url + editLectureData?.lecture_image
                        : editLectureData?.lecture_image?.type
                          ? URL.createObjectURL(editLectureData?.lecture_image)
                          : "/images/png/duser.png"
                      : selectedImage
                        ? selectedImage
                        : "/images/png/duser.png"
                  }
                  onClick={handleImageClick}
                  className={`cursor-pointer ${selectedImage
                      ? "editprofileImage"
                      : editLectureData?.lecture_image
                        ? "editprofileImage"
                        : "profileImageSize"
                    } `}
                />
              </div>
              <input
                type="file"
                accept=".jpg, .jpeg, .png"
                name="lecture_image"
                style={{ display: "none" }}
                ref={fileInputRef}
                onChange={handleFileChange}
              />

              <img
                onClick={handleImageClick}
                src="/images/svg/camera.svg"
                className="cursor-pointer position-absolute top-50 start-50 translate-middle"
                alt=""
              />
            </div>
            {Boolean(errors?.lecture_image) && (
              <div className="d-flex justify-content-center">
                <SingleError error={errors?.lecture_image} />
              </div>
            )}
          </Row>
          <Row>
            <Col md={12}>
              <label id="title">
                Title <span className="star">*</span>
              </label>
              <input
                className="form-control px-3"
                placeholder="Enter week title here"
                type="text"
                name="title"
                value={editLectureData?.title || ""}
                onChange={handleChange}
                onKeyPress={handleKeyPress}
              />
              <SingleError error={errors?.title} />
            </Col>
            <Col md={12}>
              <label id="description">
                Description <span className="star">*</span>
              </label>
              <textarea
                rows={3}
                className="form-control px-3"
                placeholder="Enter lecture description here"
                type="text"
                name="description"
                value={editLectureData?.description || ""}
                onChange={handleChange}
              />
              <SingleError error={errors?.description} />
            </Col>
          </Row>
          <div className="d-flex justify-content-center">
            <button
              className={`btn-login bg-main text-uppercase border-0 w-75 my-4 ${Boolean(loading) && "btnDisable"
                }`}
              disabled={Boolean(loading)}
              onClick={() => {
                editLecture();
                setSubmitCount(1);
              }}
            >
              {Boolean(loading) ? "Loading..." : "Save"}
            </button>
          </div>
        </Modal.Body>
      </Modal>

      {/* Delete user modal */}
      <Modal
        size="md"
        show={deleteModel}
        onHide={deleteModelCloseFun}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">Delete Lecture</Modal.Title>
          <div className="d-flex justify-content-center text-center mt-2">
            <img src="/images/svg/line.svg" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-4 border-0">
          <p className="fs-28 fw-500">Are you sure you want to delete?</p>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <div className="d-flex m-auto gap-3 pb-3 px-4">
            <button
              className="modalDeleteBtn border-0"
              onClick={() => deleteLecture()}
            >
              Delete
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default LectureList;
