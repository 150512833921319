import React, { useRef, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import QuoteTable from "../components/QuoteTable";
import Main from "../hoc/Main";
import { getServerURL } from "../helper/envConfig";
import { ValidateFields } from "../components/validate/ValidateFields";
import ErrorFilter from "../helper/errorFilter";
import api from "../helper/api";
import { toast } from "react-toastify";
import { errorResponse } from "../helper/error";
import SingleError from "../helper/SingleError";
const requireField = ["title", "description", "date"];

export const QuoteManagement = () => {
  const serverURL = getServerURL();

  const [addModel, setAddModel] = useState(false);

  const addModelOpenFun = () => {
    setSubmitCount(0);
    setErrors({});
    setState({ action: "create" });
    setAddModel(true);
  };
  const addModelCloseFun = () => setAddModel(false);
  const [state, setState] = useState({ action: "create" });
  const [submitCount, setSubmitCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState("");
  const [search, setSearch] = useState("");
  const [temp, setTemp] = useState(false);
  const [searchTemp, setSearchTemp] = useState(false);
  const handleChange = (e) => {
    setErrors({});
    const { name, value } = e.target;

    if (submitCount > 0) {
        let validationErrors = ValidateFields({ ...state, [name]: value });

        // Additional date validation
        if (name === 'date') {
            const today = new Date();
            const selectedDate = new Date(value); // Convert input value to Date object

            if (selectedDate < today) {
                validationErrors.date = "The date and time must be today or later.";
            } else {
                delete validationErrors.date; // Remove the error if the date is valid
            }
        }

        validationErrors = ErrorFilter(validationErrors, requireField);
        setErrors(validationErrors);

        if (Object.keys(validationErrors).length === 0) {
            delete errors[name];
        }
    }

    setState((prevValues) => ({
        ...prevValues,
        [name]: value,
    }));
};


  const formatDateToLocal = (dateString) => {
    const date = new Date(dateString);
    const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000); // Adjust for timezone offset
    return localDate.toISOString().slice(0, 19).replace('T', ' '); // Format: yyyy-mm-dd hh:mm:ss
  };
  const addQuote = () => {
    setTemp(false);

    // Trim whitespace from all string fields in the state
    const updatedValues = { ...state };
    Object.keys(updatedValues).forEach((key) => {
        if (typeof updatedValues[key] === "string") {
            updatedValues[key] = updatedValues[key].trim();
        }
    });

    let validationErrors = ValidateFields(updatedValues);

    // Additional date validation
    const today = new Date();
    const selectedDate = new Date(updatedValues.date); // Convert to Date object

    if (updatedValues.date) {
        if (selectedDate < today) {
            validationErrors.date = "The date and time must be today or later.";
        }
    } else {
        validationErrors.date = "Date is required.";
    }

    validationErrors = ErrorFilter(validationErrors, requireField);
    setErrors(validationErrors);

    if (Object.keys(validationErrors)?.length) {
        Object.entries(validationErrors)?.map(([key], i) => {
            if (i === 0) document.getElementById(key)?.scrollIntoView({ behavior: "smooth" });
        });
    }

    if (Object.keys(validationErrors).length === 0) {
        setLoading(true);

        // Retrieve token from localStorage
        const token = localStorage.getItem('token');

        if (!token) {
            setLoading(false);
            toast.error("Authentication token is missing");
            return;
        }

        const dataToSubmit = {
            ...updatedValues,
            date: formatDateToLocal(updatedValues.date) // Convert date to local time format
        };

        api
            .postWithToken(`${serverURL}quote-manage`, dataToSubmit, token)
            .then(async (res) => {
                setLoading(false);
                if (res?.data?.success === true) {
                    addModelCloseFun();
                    setTemp(true);
                    setState({ action: "create" });
                    toast.success(res?.data?.data?.message || "Quote created successfully");
                } else {
                    toast.error(res?.data?.message || "Something went wrong");
                }
            })
            .catch((e) => {
                setLoading(false);
                errorResponse(e);
            });
    }
};


  
  const disableAutocomplete = (e) => {
    e.target.setAttribute("autocomplete", "off");
  };

  return (
    <>
      <Main name="Quote Management">
        <div className="d-sm-block d-md-flex d-lg-flex justify-content-between align-items-center pb-3 pt-3">
          <Col md={6} lg={5} xl={7}>
            <h1 className="title mb-0">Quote Management</h1>
          </Col>
          <Col md={4} lg={4} xl={4} className="mt-3 mt-md-0">
            <div className="d-flex align-items-center gap-3">
              <div className="form-control d-flex align-items-center px-3">
                <svg
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.602 12.4131C14.0573 12.8601 14.5206 13.3151 14.9759 13.7781C15.5271 14.3289 16.0863 14.8797 16.6374 15.4384C17.0848 15.8854 17.1167 16.4043 16.7413 16.7635C16.3818 17.1147 15.8706 17.0748 15.4392 16.6437C14.5286 15.7417 13.6179 14.8318 12.7153 13.9138C12.6115 13.81 12.5396 13.6743 12.4677 13.5706C8.62541 16.516 3.84854 15.2947 1.54798 12.2455C-0.77655 9.15638 -0.457028 4.84597 2.33081 2.13999C5.10267 -0.54205 9.44019 -0.725642 12.4597 1.71693C15.4392 4.13555 16.3978 8.75727 13.602 12.4131ZM13.562 7.63975C13.57 4.34309 10.9419 1.70096 7.64287 1.69298C4.35179 1.685 1.69176 4.32712 1.69176 7.61581C1.68377 10.9045 4.32782 13.5546 7.61891 13.5626C10.918 13.5706 13.554 10.9444 13.562 7.63975Z"
                    fill="black"
                  />
                </svg>
                <input
                  className="form-control border-0 shadow-none pl-black"
                  placeholder="Enter search text here"
                  type="text"
                  name="search"
                  autoComplete="off"
                  onFocus={disableAutocomplete}
                  onChange={(e) => {
                    setSearch(e?.target?.value);
                    setSearchTemp(true);
                  }}
                />
                
              </div>
              <div onClick={() => addModelOpenFun()} className="cursor-pointer">
                <img
                  src="/images/svg/add.svg"
                  className="add-btn"
                  title="Add"
                  alt=""
                />
              </div>
            </div>
          </Col>
        </div>
        <QuoteTable temp={temp} search={search} searchTemp={searchTemp} />
      </Main>

      {/* Add user modal */}
      <Modal
        size="lg"
        show={addModel}
        onHide={() => addModelCloseFun()}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">Add Quote</Modal.Title>
          <div className="d-flex justify-content-center text-center">
            <img src="/images/svg/line.svg" className="mt-2" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-md-4 border-0 bg-transparent pt-0 ctmInput">
          <Row>
            <Col md={12}>
              <label id="title">
                Title <span className="star">*</span>
              </label>
              <input
                className="form-control px-3"
                placeholder="Enter quote title here"
                type="text"
                name="title"
                value={state?.title || ""}
                onChange={handleChange}
              />
              <SingleError error={errors?.title} />
            </Col>
            <Col md={12}>
              <label id="date">
                Date <span className="star">*</span>
              </label>
              <input
                className="form-control px-3"
                placeholder="Enter date here"
                type="datetime-local"
                name="date"
                value={state?.date || ""}
                onChange={handleChange}
              />
              <SingleError error={errors?.date} />
            </Col>
            <Col md={12}>
              <label id="description">
                Description <span className="star">*</span>
              </label>
              <textarea
                rows={3}
                className="form-control px-3"
                placeholder="Enter quote description here"
                type="text"
                name="description"
                value={state?.description || ""}
                onChange={handleChange}
              />
              <SingleError error={errors?.description} />
            </Col>
          </Row>
          <div className="d-flex  justify-content-center">
            <button
              className={`btn-login bg-main text-uppercase border-0 w-75 my-4 ${Boolean(loading) && "btnDisable"
                }`}
              disabled={Boolean(loading)}
              onClick={() => {
                addQuote();
                setSubmitCount(1);
              }}
            >
              {/* Add */}
              {Boolean(loading) ? "Loading..." : "Add"}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
