import React, { useRef, useState } from "react";
import Main from "../hoc/Main";
import { Col, Row } from "react-bootstrap";
import SingleError from "../helper/SingleError";
import { useLocation } from "react-router-dom";
import ErrorFilter from "../helper/errorFilter";
import { ValidateFields } from "../components/validate/ValidateFields";
import { getServerURL } from "../helper/envConfig";
import MyEditor from "../components/MyEditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const requireField = [
    "title",
    "description",
    "lecture_id",
    "chapter_index",
    "duration",
    "template_type",
    "content",
    "chapter_images",
];

const WeekCreate = () => {
    const location = useLocation();
    const { state } = location;
    const serverURL = getServerURL();
    const imageFileRef = useRef(null);
    const videoFileRef = useRef(null);

    const [states, setStates] = useState({});
    const [submitCount, setSubmitCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [selectedImage, setSelectedImage] = useState(null);
    const [editor, setEditor] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (submitCount > 0) {
            let validationErrors = ValidateFields({ ...states, [name]: value });
            validationErrors = ErrorFilter(validationErrors, requireField);
            setErrors(validationErrors);

            if (Object.keys(validationErrors)?.length === 0) {
                delete errors[name];
            }
        }
        setStates((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const handleImageClick = () => {
        imageFileRef.current.click();
    };
    const handleVideoClick = () => {
        videoFileRef.current.click();
    };
    // console.log(states);
    const handleFileChange = (e) => {
        const { name, value } = e.target;
        if (submitCount > 0) {
            let validationErrors = ValidateFields({ ...states, [name]: value });
            validationErrors = ErrorFilter(validationErrors, requireField);
            setErrors(validationErrors);

            if (Object.keys(validationErrors)?.length === 0) {
                delete errors[name];
            }
        }
        const file = e.target.files;
        // console.log(file);
        if (file?.length) {
            // const fileList = Array.from(file);
            const existingFiles = states[e.target.name] || [];
            const updatedFiles = existingFiles.concat(Array.from(file));
            setStates({
                ...states,
                [e.target.name]: updatedFiles,
            });
        }
    };

    const removeSelectedImages = (index) => {
        const updatedImages = states?.chapter_images.filter((_, i) => i !== index);
        setStates({
            ...states,
            ["chapter_images"]: updatedImages,
        });
    };

    const handleEditorChange = (event, editor) => {
        const data = editor.getData();
        setStates({ ...states, content: data });
    };

    return (
        <>
            <Main>
                <div className="d-sm-block d-md-flex d-lg-flex justify-content-between align-items-center align-middle pb-3 pt-3">
                    <Col md={6} lg={5} xl={7}>
                        <h1 className="title mb-0">Add Week</h1>
                    </Col>
                    <Col sm={8} md={4} lg={4} xl={4} className="mt-3 mt-md-0">
                        <div className="d-flex justify-content-end align-items-center gap-3">
                            <div className="position-relative" id="lecture_image">
                                <div className="d-flex justify-content-center align-items-center m-auto ">
                                    <img
                                        src="/images/svg/add_image.svg"
                                        onClick={handleImageClick}
                                        className={`cursor-pointer weekIconSize`}
                                    />
                                </div>
                                <input
                                    type="file"
                                    multiple
                                    accept="image/*"
                                    name="chapter_images"
                                    style={{ display: "none" }}
                                    ref={imageFileRef}
                                    onChange={handleFileChange}
                                />
                            </div>
                            <div className="position-relative" id="lecture_image">
                                <div className="d-flex justify-content-center align-items-center m-auto ">
                                    <img
                                        src="/images/svg/add_video.svg"
                                        onClick={handleVideoClick}
                                        className={`cursor-pointer weekIconSize`}
                                    />
                                </div>
                                <input
                                    type="file"
                                    accept=".mp4"
                                    name="chapter_images"
                                    // name="chapter_video"
                                    style={{ display: "none" }}
                                    ref={videoFileRef}
                                    onChange={handleFileChange}
                                />
                            </div>

                            <div
                                className={`cursor-pointer weekIconSize`}
                                onClick={() => {
                                    setEditor(true);
                                    document
                                        .getElementById("editor")
                                        ?.scrollIntoView({ behavior: "smooth", block: "center", });
                                }}
                            >
                                <img src="/images/svg/add_text.svg" className="add-btn" alt="" />
                            </div>
                        </div>
                    </Col>
                </div>
                <div className="modal-main info-modal mx-md-4 border-0 bg-transparent pt-0 ctmInput">
                    <Row>
                        {/* <MyEditor /> */}
                        <Col md={8}>
                            <label id="title">
                                Title <span className="star">*</span>
                            </label>
                            <input
                                className="form-control px-3"
                                placeholder="Enter week title here"
                                type="text"
                                name="title"
                                value={states?.title || ""}
                                onChange={handleChange}
                            />
                            <SingleError error={errors?.title} />
                        </Col>

                        <Col md={8}>
                            <label id="description">
                                Description <span className="star">*</span>
                            </label>
                            <textarea
                                className="form-control px-3"
                                placeholder="Enter week description here"
                                type="text"
                                row={3}
                                name="description"
                                value={states?.description || ""}
                                onChange={handleChange}
                            />
                            <SingleError error={errors?.description} />
                        </Col>

                        <Col md={8}>
                            <label id="lecture_id">
                                Lecture <span className="star">*</span>
                            </label>
                            <br />
                            <select
                                className="form-select px-3"
                                value={states?.lecture_id || ""}
                                name="lecture_id"
                                onChange={handleChange}
                                style={{ color: states?.lecture_id ? "#000" : "" }}
                            >
                                <option disabled value={""}>
                                    Select Lecture
                                </option>
                                {Boolean(state?.lectureList?.length) &&
                                    state?.lectureList?.map((item, i) => (
                                        <option
                                            value={item?._id}
                                            key={i + item?._id + "LectureListAdd"}
                                        >
                                            {item?.title}
                                        </option>
                                    ))}
                            </select>
                            <SingleError error={errors?.lecture_id} />
                        </Col>

                        <Col md={8}>
                            <label id="week_index">
                                Week Index <span className="star">*</span>
                            </label>
                            <br />
                            <select
                                className="form-select px-3"
                                name="week_index"
                                onChange={handleChange}
                                value={states?.chapter_index || ""}
                                style={{ color: states?.chapter_index ? "#000" : "" }}
                            >
                                <option value={""} disabled>
                                    Select Week Index
                                </option>
                                {[...Array(50)]?.map((item, i) => (
                                    <option value={i + 1} key={i + "weekIndex"}>
                                        {i + 1}
                                    </option>
                                ))}
                            </select>
                            <SingleError error={errors?.chapter_index} />
                        </Col>

                        <Col md={8}>
                            <label id="duration">
                                Time <span className="star">*</span>
                            </label>
                            <input
                                className="form-control px-3"
                                placeholder="Enter week time duration here (e.g., 10 min)"
                                type="text"
                                name="duration"
                                value={states?.duration || ""}
                                onChange={handleChange}
                            />
                            <SingleError error={errors?.duration} />
                        </Col>

                        {Boolean(states?.chapter_images?.length) && (
                            <div className="preview-images mt-3 p-3 gap-3 col-8">
                                {states?.chapter_images?.map((image, index) => (
                                    <div key={index} className="image-preview1">
                                        {image?.type?.startsWith("image/") ? (
                                            <img
                                                src={URL.createObjectURL(image)}
                                                alt={`Selected Preview image ${index}`}
                                            />
                                        ) : (
                                            <video
                                                // style={{ width: "100px", height: "100px", objectFit: "cover" }}
                                                controls
                                            >
                                                <source
                                                    src={image && URL.createObjectURL(image)}
                                                    type="video/mp4"
                                                />
                                            </video>
                                        )}
                                        <button
                                            onClick={() => removeSelectedImages(index)}
                                            className="d-flex justify-content-center"
                                        >
                                            <img
                                                src="/images/svg/closeImg.svg"
                                                style={{ width: "12px", height: "20px" }}
                                            />
                                        </button>
                                    </div>
                                ))}
                            </div>
                        )}

                        <div id={"editor"}>
                            {Boolean(editor) && (
                                <Col md={8}>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={states?.content || ""}
                                        onChange={handleEditorChange}
                                    />
                                </Col>
                            )}
                        </div>

                        <Col md={8}>
                            <div className="d-flex justify-content-center mt-3">
                                <button
                                    className={`btn-login bg-main text-uppercase border-0 w-75 my-4 
                                                ${Boolean(loading) &&
                                        "btnDisable"
                                        }`}
                                    disabled={Boolean(loading)}
                                    onClick={() => {
                                        // addWeek();
                                        // setSubmitCount(1);
                                    }}
                                >
                                    {Boolean(loading) ? "Loading..." : "Add"}
                                </button>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Main>
        </>
    );
};

export default WeekCreate;
