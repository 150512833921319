import React, { useState, useEffect, useCallback } from "react";
import { Col, Table, Modal, Row, Form } from "react-bootstrap";
import TablePagination from "./TablePagination";
import api from "../helper/api";
import { getServerURL } from "../helper/envConfig";
import { useDebounce } from "use-debounce"

const SubscriptionTable = ({ searchTemp }) => {
  const serverURL = getServerURL();

  const [subscriptions, setSubscriptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(8);
  const [pagination, setPagination] = useState({});
  const [viewModel, setViewModel] = useState(false);
  const [currentSubscription, setCurrentSubscription] = useState(null);
  const [search, setSearch] = useState(searchTemp || '');
  const [debouncedSearch] = useDebounce(search, 1000);
  const fetchSubscriptions = useCallback(() => {
    setLoading(true);
    api
      .getWithToken(`${serverURL}subscription?page=${page}&limit=${limit}&search=${debouncedSearch}`)
      .then((response) => {
        if (response?.data?.success) {
          setSubscriptions(response.data.data || []);
          setPagination(response.data.pagination || {});
          if (response.data.data.length === 0 && page > 1) {
            setPage(page - 1);
          }
        } else {
          setSubscriptions([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching subscriptions:", error.response?.data || error.message);
      })
      .finally(() => setLoading(false));
  }, [serverURL, page, limit, debouncedSearch]);



  useEffect(() => {
    fetchSubscriptions();
  }, [fetchSubscriptions]);
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setPage(1); // Reset to page 1 when search changes
  };
  const renderRows = () => {
    if (subscriptions.length === 0) {
      return (
        <tr>
          <td colSpan="7" className="text-center">
            No subscription data available
          </td>
        </tr>
      );
    }

    return subscriptions.map((subscription, index) => (
      <tr key={subscription._id}>
        <td>{(page - 1) * limit + index + 1}</td>
        <td>{subscription.userId?.username ? subscription.userId?.username : "-"}</td>
        <td>{subscription.planName ? subscription.planName : "-"}</td>
        <td> {subscription.status ? subscription.status.charAt(0).toUpperCase() + subscription.status.slice(1) : "-"}</td>
        <td>
          {subscription.purchaseDate ?
            (() => {
              const date = new Date(subscription.purchaseDate);
              const day = String(date.getDate()).padStart(2, '0');
              const month = String(date.getMonth() + 1).padStart(2, '0');
              const year = date.getFullYear();
              return `${day}-${month}-${year}`;
            })()
            : "-"}
        </td>
        <td>
          {subscription.expiryDate ?
            (() => {
              const date = new Date(subscription.expiryDate);
              const day = String(date.getDate()).padStart(2, '0');
              const month = String(date.getMonth() + 1).padStart(2, '0');
              const year = date.getFullYear();
              return `${day}-${month}-${year}`;
            })()
            : "-"}
        </td>
        <td>
          <button
            className="border-0 bg-transparent"
            onClick={() => handleViewDetails(subscription)}
          >
            <img src="/images/svg/show.svg" alt="View" />
          </button>
        </td>
      </tr>
    ));
  };

  const handleViewDetails = (subscription) => {
    setCurrentSubscription(subscription);
    setViewModel(true);
  };

  const handleModalClose = () => {
    setViewModel(false);
    setCurrentSubscription(null);
  };

  return (
    <>
      <Col className="ctmTable">
        <div className="d-sm-block d-md-flex d-lg-flex justify-content-between align-items-center pb-3 pt-3">
          <Col md={6} lg={5} xl={7}>
            <h1 className="title mb-0">Subscription Management</h1>
          </Col>
          <Col md={4} lg={4} xl={4} className="mt-3 mt-md-0">
            <Form>
              <div className="d-flex align-items-center gap-3">
                <div className="form-control d-flex align-items-center px-3">
                  <svg
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.602 12.4131C14.0573 12.8601 14.5206 13.3151 14.9759 13.7781C15.5271 14.3289 16.0863 14.8797 16.6374 15.4384C17.0848 15.8854 17.1167 16.4043 16.7413 16.7635C16.3818 17.1147 15.8706 17.0748 15.4392 16.6437C14.5286 15.7417 13.6179 14.8318 12.7153 13.9138C12.6115 13.81 12.5396 13.6743 12.4677 13.5706C8.62541 16.516 3.84854 15.2947 1.54798 12.2455C-0.77655 9.15638 -0.457028 4.84597 2.33081 2.13999C5.10267 -0.54205 9.44019 -0.725642 12.4597 1.71693C15.4392 4.13555 16.3978 8.75727 13.602 12.4131ZM13.562 7.63975C13.57 4.34309 10.9419 1.70096 7.64287 1.69298C4.35179 1.685 1.69176 4.32712 1.69176 7.61581C1.68377 10.9045 4.32782 13.5546 7.61891 13.5626C10.918 13.5706 13.554 10.9444 13.562 7.63975Z"
                      fill="black"
                    />
                  </svg>
                  <input
                    className="form-control border-0 shadow-none pl-black"
                    placeholder="Search"
                    type="text"
                    value={search}
                    onChange={handleSearchChange} // Handle the search input
                  />
                </div>

              </div>
            </Form>
          </Col>
        </div>
        <Table bordered responsive className="text-center">
          <thead className="tableHeader">
            <tr>
              <th>No</th>
              <th>User Name</th>
              <th>Plan Name</th>
              <th>Status</th>
              <th>Purchase Date</th>
              <th>Expiry Date</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody className="tableBody">{loading ? <tr><td colSpan="7" className="text-center">Loading...</td></tr> : renderRows()}</tbody>
        </Table>

        {Boolean(subscriptions.length) && pagination.totalPages > 1 && (
          <div className="d-md-flex justify-content-end align-items-center">
            <TablePagination
              currentPage={pagination.currentPage}
              totalPages={pagination.totalPages}
              onPageChange={setPage}
            />
          </div>
        )}

        {/* View Modal */}
        <Modal
          size="lg"
          show={viewModel}
          onHide={handleModalClose}
          centered
          className="modal-main info-modal"
        >
          <Modal.Header
            className="d-block position-relative border-0 pt-4 px-4"
            closeButton
          >
            <Modal.Title className="text-center">
              Subscription Details
            </Modal.Title>
            <div className="d-flex justify-content-center text-center mt-2">
              <img src="/images/svg/line.svg" />
            </div>
          </Modal.Header>
          <Modal.Body className="mx-md-4 border-0 bg-transparent pt-0 ctmInput">
            <Row>
              <div className="d-flex justify-content-center">
                <div>
                  <div className="mb-3">
                    {currentSubscription && (
                      <>
                        <div className="userDetailsRow">
                          <span className="userDetailsLabel">User Name:</span>
                          <span className="userDetailsText">{currentSubscription.userId?.username || "N/A"}</span>
                        </div>
                        <div className="userDetailsRow">
                          <span className="userDetailsLabel">Plan Name:</span>
                          <span className="userDetailsText">{currentSubscription.planName ? currentSubscription.planName : "-"}</span>
                        </div>
                        <div className="userDetailsRow">
                          <span className="userDetailsLabel">Status:</span>
                          <span className="userDetailsText">
                            {currentSubscription.status ? currentSubscription.status.charAt(0).toUpperCase() + currentSubscription.status.slice(1) : "-"}
                          </span>

                        </div>
                        <div className="userDetailsRow">
                          <span className="userDetailsLabel">Purchase Date:</span>
                          <span className="userDetailsText">
                            {currentSubscription.purchaseDate ?
                              (() => {
                                const date = new Date(currentSubscription.purchaseDate);
                                const day = String(date.getDate()).padStart(2, '0');
                                const month = String(date.getMonth() + 1).padStart(2, '0');
                                const year = date.getFullYear();
                                return `${day}-${month}-${year}`;
                              })()
                              : 'N/A'}</span>

                        </div>
                        <div className="userDetailsRow">
                          <span className="userDetailsLabel">Expiry Date:</span>
                          <span className="userDetailsText">
                            {currentSubscription.expiryDate ?
                              (() => {
                                const date = new Date(currentSubscription.expiryDate);
                                const day = String(date.getDate()).padStart(2, '0');
                                const month = String(date.getMonth() + 1).padStart(2, '0');
                                const year = date.getFullYear();
                                return `${day}-${month}-${year}`;
                              })()
                              : "-"}
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Row>
          </Modal.Body>
        </Modal>
      </Col>
    </>

  );
};

export default SubscriptionTable;
