import React, { useState, useEffect, useRef, useCallback } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import api from "../helper/api";
import { getServerURL, getImageURL } from "../helper/envConfig";
import { ValidateFields } from "../components/validate/ValidateFields";
import ErrorFilter from "../helper/errorFilter";
import { toast } from "react-toastify";
import { errorResponse } from "../helper/error";
import { useDispatch, useSelector } from "react-redux";

import Loader from "./loader/Loader";
import io from "socket.io-client";
import { decryptFromStoreLoc } from "../helper/localStorage";
import user_icon from "../../src/assets/images/user-svgrepo-com.svg";
import TablePagination from "./TablePagination";
import remove_icon from "../../src/assets/images/remove-ellipse-svgrepo-com.svg";
const MessageContent = ({
  temp,
  search,
  searchTemp,
  setSearch,
  setSearchTemp,
}) => {
  const [mainLoader, setMainLoader] = useState(true);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(8);
  const [selectedItem, setSelectedItem] = useState(null);
  const [userList, setUserList] = useState([]);
  const serverURL = getServerURL();
  const [id, setId] = useState(null);
  const [refreshData, setRefreshData] = useState(false);
  const [socket, setSocket] = useState(null);
  const imageUrl = getImageURL();
  const [message, setMessage] = useState("");
  const token = localStorage.getItem("token");
  const [messselectedImage, setMessSelectedImage] = useState([]);
  const [messages, setMessages] = useState([]);
  const [loadingMessages, setLoadingMessages] = useState(false);
  const adminId = decryptFromStoreLoc("user");
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  useEffect(() => {
    const newSocket = io(serverURL);
    setSocket(newSocket);

    // Handle incoming messages
    const handleIncomingMessages = (data) => {
      if (selectedItem && selectedItem?._id) {
        setLoadingMessages(true);
        const filteredMessages = data.data.filter(
          (msg) =>
            msg.receiver_user?._id === selectedItem?._id ||
            msg.sender_user?._id === selectedItem?._id // Include messages sent by the selected user
        );
        setMessages(filteredMessages);
        setLoadingMessages(false);
      }
    };
    newSocket.on("getMessage", handleIncomingMessages);
    if (selectedItem && selectedItem._id) {
      setMessages([]);
      newSocket.emit("getMessage", { token, receiver_user: selectedItem._id });

    }

    return () => {
      newSocket.off("getMessage", handleIncomingMessages);
      newSocket.close();
    };
  }, [selectedItem, token]);

  const getProducts = async () => {
    setMainLoader(true);
    try {
      const response = await api.getWithToken(
        `${serverURL}admin-list-message?page=${page}&limit=${limit}&search=${search}`
      );
      if (response?.data?.success) {
        setUserList(response.data.data || []);
        setTotalPages(response.data.pagination.totalPages || 1);
        setTotalItems(response.data.pagination.totalItems || 0);
        if (response.data.data.length > 0) {
          setId(response.data.data[0]._id);
        }
      } else {
        setUserList([]);
      }
    } catch (error) {
      console.error(
        "Error fetching products:",
        error.response ? error.response.data : error.message
      );
    } finally {
      setMainLoader(false);
    }
  };

  useEffect(() => {
    getProducts();
  }, [page, limit, refreshData, temp, search, searchTemp]);
  useEffect(() => {
    if (userList.length > 0) {
      setSelectedItem(userList[0]);
      setLoadingMessages(true);
    }
  }, [userList]);
  useEffect(() => {
    if (!Boolean(Object.keys(profileData)?.length)) getProfileData();
  }, []);
  const profile = useSelector((item) => item?.login);
  const [profileData, setProfileData] = useState(profile || {});
  const [profile_img, setProfile_Img] = useState("");
  const getProfileData = () => {
    api
      .postWithToken(`${serverURL}profile-view`)
      .then(async (res) => {
        setMainLoader(false);
        if (res?.data?.success === true) {
          setProfileData(res?.data?.data);
          setProfile_Img(res?.data?.data.profile_image);
        }
      })
      .catch((e) => {
        setMainLoader(false);
      });
  };
  const admin_username = decryptFromStoreLoc("username");
  const handleSubmitMessage = () => {
    // Check if there's a selected image or a message
    if (!message.trim() && messselectedImage.length > 0) {
      // Handle sending only images
      if (socket && selectedItem) {
        const currentDate = new Date().toLocaleString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        });

        const newMessage = {
          message: "",
          receiver_user: selectedItem?._id,
          created_at: currentDate,
          token,
          image: messselectedImage, // Array of images
          sender_user: {
            profile_image: profile_img,
            username: admin_username,
          },
        };

        setMessages((prevMessages) => [...prevMessages, newMessage]);
        socket.emit("sendMessage", newMessage);
        setMessSelectedImage([]); // Clear selected images
      }
    } else if (message.trim()) {
      // Check if there's a message and send it along with images if selected
      if (socket && selectedItem) {
        const currentDate = new Date().toLocaleString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        });

        const newMessage = {
          message: message,
          receiver_user: selectedItem?._id,
          created_at: currentDate,
          token,
          image: messselectedImage, // Array of images
          sender_user: {
            profile_image: profile_img,
            username: admin_username,
          },
        };

        setMessages((prevMessages) => [...prevMessages, newMessage]);
        socket.emit("sendMessage", newMessage);
        setMessage("");
        setMessSelectedImage([]); // Clear selected images
      }
    } else {
      // Handle the case where message is empty and no image is selected
      if (message.trim() === "" && messselectedImage.length === 0) {
        const errorMessage =
          "Message cannot be empty. Please enter some text or select an image.";
        toast.error(errorMessage);
      }
    }
  };
  const removeSelectedImage = (index) => {
    setMessSelectedImage((prevImages) =>
      prevImages.filter((_, i) => i !== index)
    );
  };
  const [imageModel, setImageModel] = useState(false);
  const [currentImage, setCurrentImage] = React.useState(null);
  const imageModelOpenFun = (imageUrl) => {
    setCurrentImage(imageUrl);
    setImageModel(true);
  };
  const [state, setState] = useState({
    name: "",
    description: "",
    image: null, // Changed to handle file input
  });
  const messfileInputRef = useRef(null);
  const handleMessImageClick = () => messfileInputRef.current.click();
  const MesshandleFileChange = (e) => {
    const files = Array.from(e.target.files); // Convert FileList to Array
    const validTypes = ["image/png", "image/jpeg"];
    const invalidFiles = files.filter(
      (file) => !validTypes.includes(file.type)
    );
    if (invalidFiles.length) {
      // If there are invalid files, show an error message
      const errorMessage = "Only PNG, JPEG and JPG images are allowed";
      toast.error(errorMessage);
      return;
    }
    if (files.length) {
      const fileReaders = files.map((file) => {
        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.readAsDataURL(file);
        });
      });

      Promise.all(fileReaders).then((fileDataUrls) => {
        setMessSelectedImage(fileDataUrls); // Set array of image URLs
        setState((prev) => ({ ...prev, image: files }));
      });
    }
  };
  const textareaRef = useRef(null);
  const [isUserListVisible, setUserListVisible] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState(userList);
  const handleToggleUserList = () => {
    setUserListVisible((prev) => {
      const newVisibility = !prev;
      if (newVisibility) {
        const newMessage = message + "@";
        setMessage(newMessage);
        textareaRef.current.focus();
        textareaRef.current.setSelectionRange(newMessage.length, newMessage.length);
        setFilteredUsers(userList);
      }
      return newVisibility;
    });
  };

  useEffect(() => {
    const textarea = textareaRef.current;

    const autosize = () => {
      if (textarea) {
        textarea.style.height = "28px"; // Reset height to auto to calculate scrollHeight
        // Calculate the new height and set it, ensuring it is at least 28px
        const newHeight = Math.max(textarea.scrollHeight, 28);
        textarea.style.height = `${newHeight}px`;
      }
    };

    // Set the initial height
    if (textarea) {
      textarea.style.height = "28px"; // Set the default height
    }

    // Initial autosize on component mount
    autosize();

    // Add event listener for input changes
    if (textarea) {
      textarea.addEventListener("input", autosize);
    }

    // Cleanup event listener on unmount
    return () => {
      if (textarea) {
        textarea.removeEventListener("input", autosize);
      }
    };
  }, [message]);
  const handleTextareaChange = (e) => {
    const newMessage = e.target.value;
    setMessage(newMessage);

    const cursorPos = textareaRef.current.selectionStart;
    const lastAtSymbol = newMessage.lastIndexOf("@", cursorPos - 1);

    if (lastAtSymbol !== -1) {
      const query = newMessage.substring(lastAtSymbol + 1, cursorPos).trim();

      if (query) {
        const filtered = userList.filter((user) =>
          user.username.toLowerCase().startsWith(query.toLowerCase())
        );
        setFilteredUsers(filtered);
      } else {
        setFilteredUsers(userList);
      }

      setUserListVisible(true);
    } else {
      setUserListVisible(false);
    }
  };
  const userListRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (userListRef.current && !userListRef.current.contains(event.target)) {
        setUserListVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setFilteredUsers(userList);
  }, [userList]);
  const handleUserSelect = (user) => {
    const cursorPos = textareaRef.current.selectionStart;
    const lastAtSymbol = message.lastIndexOf("@", cursorPos - 1);

    const newMessage = `${message.substring(0, lastAtSymbol + 1)}${user.username
      } ${message.substring(cursorPos)}`;
    setMessage(newMessage);
    setUserListVisible(false); // Hide the user list
  };
  const getLastMessage = (userId) => {
    const userMessages = messages.filter(msg =>
      msg.receiver_user?._id === userId || msg.sender_user?._id === userId
    );
    return userMessages.length > 0 ? userMessages[userMessages.length - 1] : null;
  };

  return (
    <>
      <Row>
        <Col md={6} sm={6} lg={4}>
          <div className="card p-2">
            <div className="form-control d-flex align-items-center px-3 mb-3 input-bg">
              <img
                src="/images/svg/search.svg"
                className="imput-img"
                alt="search.svg"
              />

              <input
                className="form-control login-input border-0"
                placeholder="Enter search text here"
                type="text"
                name="search"
                autoComplete="off"
                onChange={(e) => {
                  setSearch(e?.target?.value);
                  setSearchTemp(true);
                }}
              />
            </div>
            {userList.length > 0 ? (
              userList.map((item) => (
                <div
                  className={`card p-2 mb-2 ${selectedItem?._id === item._id ? "chat-active" : ""
                    }`}
                  key={item._id}
                  onClick={() => setSelectedItem(item)}
                >
                  <div className="user-chat-box d-flex align-items-center gap-3">
                    <img
                      src={
                        item?.profile_image
                          ? `${imageUrl}${item?.profile_image}`
                          : user_icon
                      }
                      alt={`${item?.username}'s profile`}
                      className="ml-2"
                    />
                    <div style={{ width: "80%" }}>
                      <div className="d-flex align-content-center justify-content-between gap-3">
                        <h5>{item?.username}</h5>
                        {/* <span>02/02/2024, 09:48 AM</span> */}
                      </div>
                      <p className="mb-0">{"No message"}</p>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p colSpan="3" style={{ textAlign: "center" }}>
                No data available
              </p>
            )}
          </div>
          {Boolean(userList.length) && totalPages > 1 && (
            <TablePagination
              currentPage={page}
              totalPages={totalPages}
              onPageChange={(newPage) => setPage(newPage)}
            />
          )}
        </Col>
        <Col sm={12} md={6} lg={8} className="mt-4 mt-md-0">
          <div className="borderMsg">
            <div className="position-relative p-3 chat-area">
              {selectedItem && (
                <>
                  <div className="p-3 p-sm-3 notify-tab-header">
                    <div className="user-chat-box d-flex align-items-center gap-3">
                      <img
                        src={
                          selectedItem?.profile_image
                            ? `${imageUrl}${selectedItem?.profile_image}`
                            : user_icon
                        }
                        alt=""
                        className="msguser"
                      />

                      <div>
                        <h5>{selectedItem?.username}</h5>
                        <span className="msg-time" style={{ marginLeft: "0" }}>
                          {(() => {
                            // Get the last message date
                            const lastMessage = messages[messages.length - 1];
                            if (lastMessage) {
                              const date = new Date(lastMessage.created_at);
                              const day = String(date.getDate()).padStart(2, "0");
                              const month = String(date.getMonth() + 1).padStart(2, "0");
                              const year = date.getFullYear();
                              const hours = String(date.getHours()).padStart(2, "0");
                              const minutes = String(date.getMinutes()).padStart(2, "0");
                              const ampm = hours >= 12 ? "PM" : "AM";
                              const formattedHours = hours % 12 || 12;

                              return `Last Seen at ${day}/${month}/${year}, ${formattedHours}:${minutes} ${ampm}`;
                            }
                            return "No messages available"; // Fallback if there are no messages
                          })()}
                        </span>
                      </div>
                    </div>
                  </div>

                </>
              )}
              {loadingMessages ? (
                <Loader />
              ) : messages.length > 0 ? (
                messages.map((msg, index) => (
                  <div class="p-3 p-sm-3" key={index}>
                    <ul>
                      {msg.sender_user?._id === selectedItem?._id ? (

                        <li className="receiver-msg">
                          <div className="msg d-flex align-items-start gap-3">

                            <div>
                              <p>{msg.message}</p>
                            </div>
                          </div>
                          <span className="msg-time" style={{ marginLeft: "52px" }}>
                            {(() => {
                              const date = new Date(msg.created_at);
                              const day = String(date.getDate()).padStart(2, "0");
                              const month = String(date.getMonth() + 1).padStart(2, "0");
                              const year = date.getFullYear();
                              const hours = String(date.getHours()).padStart(2, "0");
                              const minutes = String(date.getMinutes()).padStart(2, "0");
                              const ampm = hours >= 12 ? "PM" : "AM";
                              const formattedHours = hours % 12 || 12;

                              return `${day}/${month}/${year}, ${formattedHours}:${minutes} ${ampm}`;
                            })() || "Date not available"}
                          </span>
                        </li>
                      ) : (
                        <li className="send-msg ms-auto mt-3">
                          <div className="msg d-flex align-items-start gap-3" style={{ justifyContent: "end" }}>
                            <div className="send-msg-text">
                              {msg.image.length > 0 && msg.image ? (
                                msg.image.map((img, imgIndex) => (
                                  <img
                                    key={imgIndex}
                                    src={img.startsWith("data:image/") ? img : `${imageUrl}${img}`}
                                    onClick={() => imageModelOpenFun(img.startsWith("data:image/") ? img : `${imageUrl}${img}`)}
                                    style={{
                                      width: "100px",
                                      height: "100px",
                                      objectFit: "cover",
                                      marginRight: "10px",
                                      marginBottom: "10px",
                                      cursor: "pointer",
                                    }}
                                    alt={`Message Image ${imgIndex}`}
                                  />
                                ))
                              ) : (
                                <></>
                              )}
                              {msg.message && <p>{msg.message}</p>}
                            </div>
                          </div>
                          <span className="msg-time text-end w-100 d-inline-block">
                            {(() => {
                              const date = new Date(msg.created_at);
                              const day = String(date.getDate()).padStart(2, "0");
                              const month = String(date.getMonth() + 1).padStart(2, "0");
                              const year = date.getFullYear();
                              const hours = String(date.getHours()).padStart(2, "0");
                              const minutes = String(date.getMinutes()).padStart(2, "0");
                              const ampm = hours >= 12 ? "PM" : "AM";
                              const formattedHours = hours % 12 || 12;

                              return `${day}/${month}/${year}, ${formattedHours}:${minutes} ${ampm}`;
                            })() || "Date not available"}
                          </span>
                        </li>
                      )}
                    </ul>
                  </div>
                ))
              ) : (
                <div className="text-center mt-5">No messages available</div>
              )}
            </div>

            <div class="p-2 pt-0 position-relative h-auto">
              <div className="d-flex justify-content-between msgView1 px-3">
                <div className="d-flex align-items-center gap-2">
                  <img
                    src="/images/svg/pin.svg"
                    alt=""
                    onClick={handleMessImageClick}
                    style={{ cursor: "pointer" }}
                  />
                  <input
                    type="file"
                    ref={messfileInputRef}
                    style={{ display: "none" }}
                    accept="image/*"
                    multiple
                    onChange={MesshandleFileChange}
                  />
                </div>
                <textarea
                  ref={textareaRef}
                  className="p-1 w-100 text-white fs-16 fw-500 bgMsg border-0 outline-0"
                  type="text"
                  placeholder="Message"
                  name="channel"
                  value={message}
                  onChange={handleTextareaChange}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && e.shiftKey) {
                      // Insert newline on Shift + Enter
                      e.preventDefault();
                      setMessage(message + "\n");
                    } else if (e.key === "Enter") {
                      // Handle sending message on Enter without Shift
                      e.preventDefault();
                      handleSubmitMessage();
                    }
                  }}
                />

                <div className="d-flex align-items-center gap-3">
                  <span
                    className="text-white fs-22 fw-500 mess-cursor"
                    onClick={handleToggleUserList}
                  >
                    @
                  </span>
                  {isUserListVisible && (
                    <div className="user-list-dropdown" ref={userListRef}>
                      {filteredUsers.length > 0 ? (
                        filteredUsers.map((user) => (
                          <div
                            key={user?._id}
                            className="user-list-item"
                            onClick={() => handleUserSelect(user)}
                          >
                            {user.username}
                          </div>
                        ))
                      ) : (
                        setUserListVisible(false)
                      )}
                    </div>
                  )}
                  <img
                    src="/images/svg/send.svg"
                    alt=""
                    onClick={handleSubmitMessage}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>
              {messselectedImage && messselectedImage.length > 0 && (
                <div className="d-flex gap-2 images-wrapper">
                  {messselectedImage.map((imgSrc, index) => (
                    <div className="msg-images">
                      <img
                        key={index}
                        src={imgSrc}
                        alt={`Selected Preview ${index}`}
                        style={{
                          width: "40px",
                          height: "40px",
                          objectFit: "cover",
                        }}
                      />
                      <div className="rem-img-icon">
                        <img
                          src={remove_icon}
                          onClick={() => removeSelectedImage(index)}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </Col>
      </Row>
      <Modal
        size="lg"
        show={imageModel}
        onHide={() => setImageModel(false)}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <div className="d-flex justify-content-center text-center mt-2">
            <img src="/images/svg/line.svg" className="mt-2" alt="" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-md-4 border-0 bg-transparent ctmInput">
          {currentImage && (
            <img
              className="rounded-3 community-modal"
              src={currentImage}
              alt="Modal Image"
              style={{ width: "100%", height: "600px" }}
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default MessageContent;
