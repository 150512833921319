import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import api from "../helper/api";
import { getImageURL, getServerURL } from "../helper/envConfig";
import { ValidateFields } from "../components/validate/ValidateFields";
import ErrorFilter from "../helper/errorFilter";
import Main from "../hoc/Main";
import SingleError from "../helper/SingleError";
import Loader from "../components/loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { setLogin } from "../redux/action/LoginAction";
import { errorResponse } from "../helper/error";
const requireField = ["email", "first_name", "last_name", "contact_no"];

const Profile = () => {
  const fileInputRef = useRef(null);
  const serverURL = getServerURL();
  const url = getImageURL();
  const dispatch = useDispatch();
  const profile = useSelector((item) => item?.login);

  const [profileData, setProfileData] = useState(profile || {});
  const [editModel, setEditModel] = useState(false);
  const [errors, setErrors] = useState({});
  const [submitCount, setSubmitCount] = useState(0);
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [mainLoader, setMainLoader] = useState(false);

  const [state, setState] = useState({
    // name: profileData?.name || "",
    // email: profileData?.email || "",
    // first_name: profileData?.first_name || "",
    // last_name: profileData?.last_name || "",
    // contact_no: profileData?.contact_no || "",
  });

  const editModelOpenFun = () => {
    setEditModel(true);
    setErrors({});
    setSubmitCount(0);
    setSelectedImage(null);
    setState({
      name: profileData?.name || "",
      email: profileData?.email || "",
      first_name: profileData?.first_name || "",
      last_name: profileData?.last_name || "",
      contact_no: profileData?.contact_no || "",
      profile_image: profileData?.profile_image,
    });
  };
  const editModelCloseFun = () => setEditModel(false);

  useEffect(() => {
    if (!Boolean(Object.keys(profileData)?.length)) getProfileData();
  }, []);

  const getProfileData = () => {
    api
      .postWithToken(`${serverURL}profile-view`)
      .then(async (res) => {
        // console.log(res);
        setMainLoader(false);
        if (res?.data?.success === true) {
          dispatch(setLogin(res?.data?.data));
          setProfileData(res?.data?.data);
          setState({
            name: res?.data?.data?.name || "",
            email: res?.data?.data?.email || "",
            first_name: res?.data?.data?.first_name || "",
            last_name: res?.data?.data?.last_name || "",
            contact_no: res?.data?.data?.contact_no || "",
            profile_image: res?.data?.data?.profile_image || "",
          });
        }
      })
      .catch((e) => {
        // console.log(e);
        setMainLoader(false);
      });
  };

  const handleChange = (e) => {
    setErrors({});
    const { name, value } = e.target;
  
    // Validate the "Contact No" field to ensure it only contains numeric values
    if (name === "contact_no" && isNaN(value)) {
      setErrors(prevErrors => ({
        ...prevErrors,
        contact_no: "Contact number must be numeric."
      }));
      return;
    }
  
    if (submitCount > 0) {
      let validationErrors = ValidateFields({ ...state, [name]: value });
      validationErrors = ErrorFilter(validationErrors, requireField);
      setErrors(validationErrors);
  
      if (Object.keys(validationErrors).length === 0) {
        delete errors[name];
      }
    }
  
    setState((prevVal) => ({
      ...prevVal,
      [name]: value,
    }));
  };
  

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
      setState({
        ...state,
        [e.target.name]: e.target.files[0],
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Trim whitespace from all relevant string fields in state
    const updatedValues = {
        ...state,
        name: state?.name?.trim() || "",
        email: state?.email?.trim() || "",
        first_name: state?.first_name?.trim() || "",
        last_name: state?.last_name?.trim() || "",
        contact_no: state?.contact_no?.trim() || ""
    };

    let validationErrors = ValidateFields(updatedValues);
    validationErrors = ErrorFilter(validationErrors, requireField);
    setErrors(validationErrors);

    if (Object.keys(validationErrors)?.length) {
        Object.entries(validationErrors)?.map(([key], i) => {
            if (i == 0)
                document.getElementById(key)?.scrollIntoView({ behavior: "smooth" });
        });
    }

    if (Object.keys(validationErrors).length === 0) {
        setLoading(true);
        setMainLoader(true);
        try {
            const formData = new FormData();
            formData.append("name", updatedValues.name);
            formData.append("email", updatedValues.email);
            formData.append("first_name", updatedValues.first_name);
            formData.append("last_name", updatedValues.last_name);
            formData.append("contact_no", updatedValues.contact_no);
            formData.append("profile_image", state?.profile_image); // Assuming this is a file and doesn't need trimming

            api
                .postWithToken(`${serverURL}edit-profile`, formData)
                .then(async (res) => {
                    setMainLoader(false);
                    setLoading(false);
                    if (res?.data?.success === true) {
                        getProfileData();
                        editModelCloseFun();
                        toast.success("Profile updated successfully!");
                    }
                })
                .catch((e) => {
                    errorResponse(e);
                    setMainLoader(false);
                    if (
                        e?.response?.data?.message ===
                        "Invalid file type for profile image or icon. Only JPG, JPEG, and PNG are allowed."
                    )
                        document
                            .getElementById("profile_image")
                            ?.scrollIntoView({ behavior: "smooth" });
                });
        } catch (error) {
            errorResponse(error);
            setMainLoader(false);
            setLoading(false);
        }
    }
};


const handleKeyPress = (e) => {
  // Allow only numeric input for "Contact No" field
  if (e.target.name === "contact_no" && !/^[0-9]$/.test(e.key)) {
    e.preventDefault(); // Prevent non-numeric input
  }

  // Handle form submission on Enter key press
  if (e.key === "Enter") {
    setSubmitCount(1);
    handleSubmit(e);
  }
};


  return (
    <>
      <Main name="Profile">
        {Boolean(mainLoader) ? (
          <Loader />
        ) : (
          <div className="card mt-4">
            <div className="d-flex p-4">
              <div className="d-flex align-items-center">
                <div className="position-relative">
                  <div className="d-flex justify-content-center m-auto ">
                    <img
                      src={
                        profileData?.profile_image
                          ? url + profileData?.profile_image
                          : "/images/png/duser.png"
                      }
                      className="profileImage cursor-pointer"
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between w-100 pl-4">
                <table>
                  <tr>
                    <td className="profileText1">Name :</td>
                    <td className="profileText2">
                      {profileData?.first_name + " " + profileData?.last_name ||
                        "-"}
                    </td>
                  </tr>
                  <tr>
                    <td className="profileText1">Email Id:</td>
                    <td className="profileText2">
                      {profileData?.email || "-"}
                    </td>
                  </tr>
                  <tr>
                    <td className="profileText1">First Name:</td>
                    <td className="profileText2">
                      {profileData?.first_name || "-"}
                    </td>
                  </tr>
                  <tr>
                    <td className="profileText1">Last Name:</td>
                    <td className="profileText2">
                      {profileData?.last_name || "-"}
                    </td>
                  </tr>
                  <tr>
                    <td className="profileText1">Contact No:</td>
                    <td className="profileText2">
                      {profileData?.contact_no || "-"}
                    </td>
                  </tr>
                </table>
                <button
                  className="profileEdit border-0"
                  onClick={() => editModelOpenFun()}
                >
                  Edit
                </button>
              </div>
            </div>
          </div>
        )}
      </Main>

      {/* Edit user modal */}
      <Modal
        size="lg"
        show={editModel}
        onHide={editModelCloseFun}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">Edit Profile</Modal.Title>
          <div className="d-flex justify-content-center text-center mt-2">
            <img src="/images/svg/line.svg" className="mt-2" />
          </div>
        </Modal.Header>

        <Modal.Body className="mx-md-4 border-0 bg-transparent pt-0 ctmInput">
          <Form onSubmit={handleSubmit}>
            <Row>
              <div className="position-relative my-4" id="profile_image">
                <div className="d-flex justify-content-center align-items-center m-auto profileImage">
                  <img
                    src={
                      state?.profile_image
                        ? typeof state?.profile_image === "string"
                          ? url + state?.profile_image
                          : state?.profile_image?.type
                            ? URL.createObjectURL(state?.profile_image)
                            : "/images/png/duser.png"
                        : selectedImage
                          ? selectedImage
                          : "/images/png/duser.png"

                      // selectedImage
                      //   ? selectedImage
                      //   : profileData?.profile_image
                      //     ? url + profileData?.profile_image
                      //     : state?.profile_image
                      //       ? url + state?.profile_image
                      //       : "/images/png/duser.png"
                    }
                    onClick={handleImageClick}
                    className={`cursor-pointer ${selectedImage
                        ? "editprofileImage"
                        : profileData?.profile_image
                          ? "editprofileImage"
                          : "profileImageSize"
                      } `}
                  />
                </div>
                <input
                  type="file"
                  // accept="image/*"
                  accept=".jpg, .jpeg, .png"
                  name="profile_image"
                  style={{ display: "none" }}
                  ref={fileInputRef}
                  onChange={handleFileChange}
                />

                <img
                  src="/images/svg/camera.svg"
                  onClick={handleImageClick}
                  className="cursor-pointer position-absolute top-50 start-50 translate-middle"
                  alt=""
                />
              </div>
            </Row>
            <Row>
              {/* <Col md={12}>
                <label>Name</label>
                <input
                  className="form-control px-3 mb-3"
                  placeholder="Name"
                  type="text"
                  name="name"
                  value={state?.name || ""}
                  onChange={handleChange}
                />
                <div className='error text-danger mb-3' >{errors?.name}</div>
              </Col> */}
              <Col md={12}>
                <label>Email</label>
                <input
                  className="form-control px-3"
                  placeholder="Email"
                  type="text"
                  name="email"
                  disabled
                  value={state?.email || ""}
                  onChange={handleChange}
                  onKeyPress={handleKeyPress}
                />
                <SingleError error={""} />
              </Col>
              <Col md={12}>
                <label id="first_name">
                  First Name <span className="star">*</span>
                </label>
                <input
                  className="form-control px-3"
                  placeholder="Enter first name here"
                  type="text"
                  name="first_name"
                  value={state?.first_name || ""}
                  onChange={handleChange}
                  onKeyPress={handleKeyPress}
                />
                <SingleError error={errors?.first_name} />
              </Col>
              <Col md={12}>
                <label id="last_name">
                  Last Name <span className="star">*</span>
                </label>
                <input
                  className="form-control px-3"
                  placeholder="Enter last name here"
                  type="text"
                  name="last_name"
                  value={state?.last_name || ""}
                  onChange={handleChange}
                  onKeyPress={handleKeyPress}
                />
                <SingleError error={errors?.last_name} />
              </Col>
              <Col md={12}>
  <label id="contact_no">
    Contact No <span className="star">*</span>
  </label>
  <input
    className="form-control px-3"
    placeholder="Enter contact number here"
    type="text"
    name="contact_no"
    value={state?.contact_no || ""}
    onChange={handleChange}
    onKeyPress={handleKeyPress}
  />
  <SingleError error={errors?.contact_no} />
</Col>

            </Row>
            <div className="d-flex justify-content-center">
              <button
                className={`btn-login bg-main text-uppercase border-0 w-75 my-4 ${Boolean(loading) && "btnDisable"
                  }`}
                onClick={() => setSubmitCount(1)}
                disabled={loading}
              >
                {Boolean(loading) ? "Loading... " : "Save"}
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Profile;
