import React, { useState } from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { getServerURL } from "../helper/envConfig";
import { validate } from "./LoginSchema";
import api from "../helper/api";
import { login } from "../helper/auth";
import { toast } from "react-toastify";
import { errorResponse } from "../helper/error";
import SingleError from "../helper/SingleError";

const Login = () => {
  const initialValues = {
    email: "",
    password: "",
  };

  const serverURL = getServerURL();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [submitCount, setSubmitCount] = useState(0);

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleChange = (e) => {
    setErrors({});
    const { name, value, checked, type } = e.target;
    let newValue = type === "checkbox" ? checked : value;
    if (submitCount > 0) {
      const validationErrors = validate({ ...values, [name]: newValue });
      setErrors(validationErrors);
      if (Object.keys(validationErrors).length === 0) {
        delete errors[name];
      }
    }

    setValues((prevValues) => ({
      ...prevValues,
      [name]: newValue,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const updatedValues = { ...values };

    const validationErrors = validate(updatedValues);
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      setLoading(true);
      try {
        api
          .post(`${serverURL}login`, updatedValues)
          .then(async (res) => {
            setLoading(false);
            if (res?.data?.success === true) {
              if (res?.data?.adminData) {
                login(res?.data?.adminData);
                toast.success("Login Successfully");
                navigate("/");
              }
            } else if (res?.data?.success === false) {
              if (typeof res?.data?.message === "string")
                toast.error(res?.data?.message);
            }
          })
          .catch((e) => {
            setLoading(false);
            errorResponse(e);
          });
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      setSubmitCount(1);
      handleSubmit(e);
    }
  };

  return (
    <div>
      <section className="d-flex justify-content-center align-items-center vh-100">
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col className="col-12 col-md-7 col-lg-6 col-xl-5">
              <Card className="login-box">
                <Card.Img
                  variant="top"
                  className="login-logo m-auto"
                  src="/images/logo/logo.png"
                />
                <p className="login-title">
                  Welcome back! Glad <br />
                  to see you, Again!
                </p>
                <Card.Body className="px-0 px-md-3">
                  <Form onSubmit={handleSubmit}>
                    <div className="form-control d-flex align-items-center px-3  input-bg ctmInput">
                      <img
                        src="/images/svg/sms.svg"
                        className="imput-img"
                        alt="sms.svg"
                      />
                      <input
                        className="form-control login-input border-0"
                        placeholder="E-mail"
                        type="text"
                        name="email"
                        onKeyPress={handleKeyPress}
                        onChange={handleChange}
                        value={values?.email || ""}
                      />
                    </div>
                    <SingleError error={errors?.email} />
                    {/* <div className="error text-danger mb-3">
                      {errors?.email}
                    </div> */}
                    <div className="form-control d-flex align-items-center px-3 input-bg ctmInput">
                      <img
                        src="/images/svg/lock.svg"
                        className="imput-password-img"
                        alt="password.svg"
                      />
                      <input
                        className="form-control login-input border-0"
                        placeholder="Password"
                        type={showPassword ? "text" : "password"}
                        name="password"
                        onChange={handleChange}
                        onKeyPress={handleKeyPress}
                        value={values?.password || ""}
                      />
                      <img
                        src={
                          showPassword
                            ? "/images/svg/open-eye.svg"
                            : "/images/svg/close-eye.svg"
                        }
                        className="close-eye-img cursor-pointer"
                        alt={showPassword ? "open-eye.svg" : "close-eye.svg"}
                        onClick={togglePasswordVisibility}
                      />
                    </div>
                    <SingleError error={errors?.password} />
                    {/* <div className="error text-danger mb-3">
                      {errors?.password}
                    </div> */}
                    <p
                      className="text-end fs-16 text-muted fw-600 cursor-pointer"
                      onClick={() => navigate("/forgot-password")}
                    >
                      Forgot password?
                    </p>
                    <div className="text-danger" style={{ textAlign: "left" }}>
                      { }
                    </div>
                    <button
                      disabled={loading}
                      className={`btn-login bg-main text-uppercase border-0 w-100 mt-4 ${Boolean(loading) && "btnDisable"
                        }`}
                      type="submit"
                      onClick={() => setSubmitCount(1)}
                    >
                      {Boolean(loading) ? "Loading... " : "login"}
                    </button>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default Login;
