import React, { useState,useEffect } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import UserTable from "../components/UserTable";
import Main from "../hoc/Main";
import api from "../helper/api";
import { getImageURL, getServerURL } from "../helper/envConfig";

export const UserManagement = () => {
  const [addModel, setAddModel] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const [search, setSearch] = useState("");
  const [temp, setTemp] = useState(false);
  const [searchTemp, setSearchTemp] = useState(false);
  const serverURL = getServerURL();
  const fetchData = async () => {
    try {
      const response = await api.getWithToken(`${serverURL}get-user`);
      if (response?.data?.success) {
        return response?.data?.data || [];
      } else {
        console.error("API Error Message:", response?.data?.message);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    return [];
  };
  useEffect(() => {
    const getData = async () => {
      const data = await fetchData();
    };
    getData();
  }, [refreshData]);
  
  return (
    <>
      <Main name="User Management">
        <div className="d-sm-block d-md-flex d-lg-flex justify-content-between align-items-center pb-3 pt-3 ">
          
          <Col md={4} lg={4} xl={4} className="mt-3 mt-md-0">
            <Form>
              <div className="d-flex align-items-center gap-3">
             
                
                {/* <div
                  onClick={() => setAddModel(true)}
                  className="cursor-pointer"
                >
                  <img src="/images/svg/add.svg" className="add-btn"
                  title="Add"
                  alt="" />
                </div> */}
              </div>
            </Form>
          </Col>
        </div>
        <UserTable refresh={refreshData} temp={temp} search={search} searchTemp={searchTemp} setSearch={setSearch} setSearchTemp={setSearchTemp} />
      </Main>

      {/* Add user modal */}
      <Modal
        size="lg"
        show={addModel}
        onHide={() => setAddModel(false)}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">Add User</Modal.Title>
          <div className="d-flex justify-content-center text-center">
            <img src="/images/svg/line.svg" className="mt-2" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-md-4 border-0 bg-transparent pt-0 ctmInput">
          <Form>
            <Row>
              <div className="position-relative my-4">
                <div className="d-flex justify-content-center align-items-center m-auto profileImage">
                  <img
                    src="/images/svg/edituser.svg"
                    className="profileImageSize cursor-pointer"
                  />
                </div>

                <input
                  type="file"
                  accept="image/*"
                  name="profile_image"
                  style={{ display: "none" }}
                />

                <img
                  src="/images/svg/camera.svg"
                  className="cursor-pointer position-absolute top-50 start-50 translate-middle"
                  alt=""
                />
              </div>
            </Row>
            <Row>
              <Col md={12}>
                <label>Name</label>
                <input
                  className="form-control px-3 mb-3"
                  placeholder="Name"
                  type="text"
                  name="name"
                />
              </Col>
              <Col md={12}>
                <label>Email</label>
                <input
                  className="form-control px-3 mb-3"
                  placeholder="Email"
                  type="text"
                  name="name"
                />
              </Col>
              <Col md={12}>
                <label>Password</label>
                <input
                  className="form-control px-3 mb-3"
                  placeholder="Password"
                  type="text"
                  name="name"
                />
              </Col>
              {/* <Col md={12}>
                <label>Contact No</label>
                <input
                  className="form-control px-3 mb-3"
                  placeholder="Contact"
                  type="text"
                  name="name"
                />
              </Col> */}
              {/* <Col xs={12} lg={12} xl={12} className="pt-2">
                <div class="upload__btn d-flex align-items-center justify-content-center py-2">
                  <label class="d-flex align-items-center gap-1">
                    <span>+</span>
                    <p className="mb-0">Upload Profxile image</p>
                    <input
                      type="file"
                      data-max_length="20"
                      class="upload_inputfile"
                      name="sub_images"
                    />
                  </label>
                </div>
              </Col> */}
            </Row>
            <div className="d-flex  justify-content-center">
              <button className=" btn-login bg-main text-uppercase border-0 w-75 my-4">
                Add
              </button>
            </div>
          </Form>
          {/* <div className="mx-md-4 border-0 bg-transparent mt-2 mb-3">
            <div className="d-flex justify-content-center text-center mt-4">
              <Button className="modelBtn">Add</Button>
            </div>
          </div> */}
        </Modal.Body>
      </Modal>
    </>
  );
};
