import React, { useRef, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import SubscriptionTable from "../components/SubscriptionTable";
import Main from "../hoc/Main";
import api from "../helper/api";
import { ValidateFields } from "../components/validate/ValidateFields";
import ErrorFilter from "../helper/errorFilter";
import { getImageURL, getServerURL } from "../helper/envConfig";
import { errorResponse } from "../helper/error";
import { toast } from "react-toastify";
const requireField = ["videoUrl"];

export const SubscriptionManagement = () => {
  const [addModel, setAddModel] = useState(false);
  const serverURL = getServerURL();
  const imageURL = getImageURL();
  const fileInputRef = useRef(null);

  const addModelOpenFun = () => {
    setSubmitCount(0);
    setErrors({});
    setState({});
    setAddModel(true);
  };

  const addModelCloseFun = () => setAddModel(false);

  const [state, setState] = useState({});
  const [submitCount, setSubmitCount] = useState(0);
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState("");
  const [search, setSearch] = useState("");
  const [temp, setTemp] = useState(false);
  const [searchTemp, setSearchTemp] = useState(false);

  const handleChange = (e) => {
    setErrors({});
    const { name, value } = e.target;
    if (submitCount > 0) {
      let validationErrors = ValidateFields({ ...state, [name]: value });
      validationErrors = ErrorFilter(validationErrors, requireField);
      setErrors(validationErrors);

      if (Object.keys(validationErrors).length === 0) {
        delete errors[name];
      }
    }
    setState((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
      setState({
        ...state,
        [e.target.name]: e.target.files[0],
      });
    }
  };

  const addSubscription = () => {
    setTemp(false);
    const updatedValues = { ...state };
    let validationErrors = ValidateFields(updatedValues);
    validationErrors = ErrorFilter(validationErrors, requireField);
    setErrors(validationErrors);

    if (Object.keys(validationErrors)?.length) {
      Object.entries(validationErrors)?.map(([key], i) => {
        if (i == 0)
          document.getElementById(key)?.scrollIntoView({ behavior: "smooth" });
      });
    }

    if (Object.keys(validationErrors).length === 0) {
      setLoading(true);
      try {
        api
          .postWithToken(`${serverURL}subscription-plans-manage`, {
            action: "create",
            videoUrl: state?.videoUrl || "",
          })
          .then(async (res) => {
            setLoading(false);
            if (res?.data?.success === true) {
              addModelCloseFun();
              setTemp(true);
              setState({});
              if (typeof res?.data?.data?.message === "string")
                toast.success(res?.data?.data?.message);
              else toast.success("Subscription created successfully");
            }
          })
          .catch((e) => {
            // console.log("eeee", e);
            setLoading(false);
            errorResponse(e);
          });
      } catch (error) {
        setLoading(false);
        // console.log(error);
      }
    }
  };


  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      setSubmitCount(1);
      addSubscription();
    }
  };

  return (
    <>
      <Main name="Subscription Management">
        <SubscriptionTable temp={temp} search={search} searchTemp={searchTemp} />
      </Main>

      {/* Add user modal */}
      <Modal
        size="lg"
        show={addModel}
        onHide={() => setAddModel(false)}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">Add Subscription</Modal.Title>
          <div className="d-flex justify-content-center text-center">
            <img src="/images/svg/line.svg" className="mt-2" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-md-4 border-0 bg-transparent pt-0 ctmInput">
          <Form>
            <Row>
              <div className="position-relative my-4">
                <div className="d-flex justify-content-center align-items-center m-auto profileImage">
                  <img
                    src="/images/svg/edituser.svg"
                    className="profileImageSize cursor-pointer"
                  />
                </div>

                <input
                  type="file"
                  accept="image/*"
                  name="profile_image"
                  style={{ display: "none" }}
                />

                <img
                  src="/images/svg/camera.svg"
                  className="cursor-pointer position-absolute top-50 start-50 translate-middle"
                  alt=""
                />
              </div>
            </Row>
            <Row>
              <Col md={12} id="name">
                <label>Name <span className="star">*</span></label>
                <input
                  className="form-control px-3 mb-3"
                  placeholder="Name"
                  type="text"
                  name="name"
                  value={state?.name || ""}
                  onChange={handleChange}
                  onKeyPress={handleKeyPress}
                />
              </Col>
              <Col md={12}>
                <label>Type <span className="star">*</span></label>
                <input
                  className="form-control px-3 mb-3"
                  placeholder="Type"
                  type="text"
                  name="type"
                  value={state?.type || ""}
                  onChange={handleChange}
                  onKeyPress={handleKeyPress}
                />
              </Col>
              <Col md={12} id="date">
                <label>Date</label>
                <input
                  className="form-control px-3 mb-3"
                  placeholder="date"
                  type="date"
                  name="date"
                  value={state?.date || ""}
                  onChange={handleChange}
                  onKeyPress={handleKeyPress}
                />
              </Col>
            </Row>
            <div className="d-flex  justify-content-center">
              <button className=" btn-login bg-main text-uppercase border-0 w-75 my-4">
                Add
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};
