import React, { useEffect, useRef, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import api from "../../helper/api";
import { ValidateFields } from "../validate/ValidateFields";
import { errorResponse } from "../../helper/error";
import { getImageURL, getServerURL } from "../../helper/envConfig";
import ErrorFilter from "../../helper/errorFilter";
import Loader from "../loader/Loader";
import SingleError from "../../helper/SingleError";
const requireField = ["home_page_image", "title", "description"];

const HomeManage = ({ temp, search, searchTemp, setCountHome }) => {
  const serverURL = getServerURL();
  const imageURL = getImageURL();
  const fileInputRef = useRef(null);

  const [state, setState] = useState();
  const [homeDataList, setHomeDataList] = useState([]);
  const [paginationData, setPaginationData] = useState({});
  const [loading, setLoading] = useState(false);
  const [submitCount, setSubmitCount] = useState(0);
  const [errors, setErrors] = useState({});
  const [mainLoader, setMainLoader] = useState(true);
  const [editData, setEditData] = useState({});
  const [deleteId, setDeleteId] = useState("");
  const [editModel, setEditModel] = useState(false);
  const [deleteModel, setDeleteModel] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState("");
  const [totalPages, SetTotalPages] = useState();

  const editModelOpenFun = () => {
    setErrors({});
    setSubmitCount(0);
    setEditModel(true);
  };
  const editModelCloseFun = () => setEditModel(false);

  const deleteModelOpenFun = () => setDeleteModel(true);
  const deleteModelCloseFun = () => setDeleteModel(false);

  useEffect(() => {
    getHomeMangeList();
  }, []);

  useEffect(() => {
    getHomeMangeList();
  }, [page]);

  useEffect(() => {
    if (temp || Boolean(search)) {
      getHomeMangeList();
    }
    if (searchTemp && !search) {
      getHomeMangeList();
    }
  }, [temp, search]);

  useEffect(() => {
    if (search?.length) setPage(1);
  }, [search]);

  const handleChange = (e) => {
    setErrors({});
    const { name, value } = e.target;
    if (submitCount > 0) {
      let validationErrors = ValidateFields({ ...editData, [name]: value });
      validationErrors = ErrorFilter(validationErrors, requireField);
      setErrors(validationErrors);

      if (Object.keys(validationErrors).length === 0) {
        delete errors[name];
      }
    }
    setEditData((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleImagesChange = (e) => {
    const { name } = e.target;
    
    if (submitCount > 0) {
      let validationErrors = ValidateFields({ ...editData, [name]: e.target.files });
      validationErrors = ErrorFilter(validationErrors, requireField);
      setErrors(validationErrors);
  
      if (Object.keys(validationErrors)?.length === 0) {
        delete errors[name];
      }
    }
  
    const files = Array.from(e.target.files); // Get all selected files
    setEditData({
      ...editData,
      [name]: [...(editData[name] || []), ...files], // Append new files to existing ones
    });
  };

  const removeSelectedImages = (index, id) => {
    const updatedImages = editData?.home_page_image.filter((_, i) => i !== index);
    setEditData(prevEditData => ({
        ...prevEditData,
        home_page_image: updatedImages,
        imagesToRemove: id
            ? prevEditData.imagesToRemove
                ? [...prevEditData.imagesToRemove, id]
                : [id]
            : prevEditData.imagesToRemove,
    }));
};

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      setSubmitCount(1);
      editHomeMange();
    }
  };

  const getHomeMangeList = () => {
    try {
      api
        .postWithToken(
          `${serverURL}ui-page-manage?page=${page}&limit=${limit}&search=${search}}`,
          { action: "home-list" }
        )
        .then(async (res) => {
          // console.log("====", res);
          if (res?.data && Object.keys(res?.data?.data)?.length) {
            setCountHome(1);
          }
          setMainLoader(false);
          // const sortedData = res?.data?.data.sort((a, b) => a.chapter_index - b.chapter_index);
          if (res?.data?.success === true) {
            setState(res?.data?.data);
            setPaginationData(res?.data?.pagination);
          }
        })
        .catch((e) => {
          // console.log(e);
          setMainLoader(false);
          if (
            e?.response?.status === 404 ||
            e?.response?.statusText === "Not Found"
          ) {
            setState([]);
            if (Boolean(page != 1)) {
              setPage(page - 1);
            }
          }
          // errorResponse(e);
        });
    } catch (error) {}
  };

  const editHomeMange = () => {
    const updatedValues = { ...editData };
    let validationErrors = ValidateFields(updatedValues);
    validationErrors = ErrorFilter(validationErrors, requireField);
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length) {
        Object.entries(validationErrors).forEach(([key], i) => {
            if (i === 0)
                document.getElementById(key)?.scrollIntoView({ behavior: "smooth" });
        });
    } else {
        setLoading(true);
        const formData = new FormData();
        formData.append("action", "home-update");
        formData.append("title", editData?.title);
        formData.append("description", editData?.description);
        formData.append("id", editData?._id);

        // Send images to remove
        if (editData?.imagesToRemove) {
            formData.append("imagesToRemove", JSON.stringify(editData.imagesToRemove));
        }

        // Handle new images
        if (Array.isArray(editData?.home_page_image)) {
            editData?.home_page_image.forEach((subImage) => {
                if (subImage.name) { // Check if it's a new image (File object)
                    formData.append("home_page_image", subImage);
                }
            });
        }

        try {
            api
                .postWithToken(`${serverURL}ui-page-manage`, formData)
                .then(async (res) => {
                    setLoading(false);
                    if (res?.data?.success) {
                        getHomeMangeList();
                        editModelCloseFun();
                        setEditData({});
                        toast.success(res?.data?.message || "Home management detail updated successfully");
                    }
                })
                .catch((e) => {
                    setLoading(false);
                    errorResponse(e);
                });
        } catch (error) {
            setLoading(false);
        }
    }
};

  const deleteHomeMange = () => {
    try {
      api
        .postWithToken(`${serverURL}ui-page-manage`, {
          action: "home_delete",
          id: deleteId,
        })
        .then(async (res) => {
          setDeleteId("");
          getHomeMangeList();
          if (state?.length == 1) {
            setState([]);
          }
          deleteModelCloseFun();
          if (res?.data?.success === true) {
            if (typeof res?.data?.message === "string")
              toast.success(res?.data?.message);
            else toast?.success("Review deleted successfully");
          }
        })
        .catch((e) => {
          errorResponse(e);
        });
    } catch (error) {}
  };

  return (
    <>
      {Boolean(mainLoader) ? (
        <Loader />
      ) : Boolean(Object.keys(state)?.length) ? (
        <div className="row gap-2">
          {/* {[...Array(1)]?.map((item, i) => */}
          <div className="card p-3 my-3 col-6">
            <div className="d-flex justify-content-between align-items-center gap-2">
              <div className="explore-head col-10">{state?.title || "-"}</div>
              <div>
                <button
                  className="border-0 bg-transparent"
                  onClick={() => {
                    editModelOpenFun();
                    setEditData(state);
                  }}
                >
                  <img src="/images/svg/edit.svg" title="Edit" />
                </button>
                {/* <button
                                    className="border-0 bg-transparent"
                                    onClick={() => {
                                        deleteModelOpenFun();
                                        setDeleteId(state?._id) 
                                    }}
                                >
                                    <img src="/images/svg/delete.svg" title="Edit" />
                                </button> */}
              </div>
            </div>
            <div className="explore-description col-10">
              {state?.description || "-"}
            </div>

            <div className="mt-4 mb-3 d-flex gap-3 row">
              {Boolean(state?.home_page_image) &&
                Boolean(state?.home_page_image?.length) &&
                state?.home_page_image?.map((item, i) => (
                  <div className="col-2" key={item?._id + "home-content"}>
                    <img
                      src={item?.file_name && imageURL + item?.file_name}
                      className="home-image"
                      alt={`Selected Preview image}`}
                    />
                  </div>
                ))}
            </div>
          </div>
          {/* )} */}
        </div>
      ) : (
        <h4 className="text-center p-5">No Records Founds.</h4>
      )}

      {/* Add modal */}
      <Modal
        size="lg"
        show={editModel}
        onHide={() => setEditModel(false)}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">
            Edit Home Management
          </Modal.Title>
          <div className="d-flex justify-content-center text-center">
            <img src="/images/svg/line.svg" className="mt-2" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-md-4 border-0 bg-transparent pt-0 ctmInput">
          <Row>
            <Col md={12}>
              <label id="title">
                Title <span className="star">*</span>
              </label>
              <input
                className="form-control px-3"
                placeholder="Enter home title here"
                type="text"
                name="title"
                value={editData?.title || ""}
                onChange={handleChange}
                onKeyPress={handleKeyPress}
              />
              <SingleError error={errors?.title} />
            </Col>
            <Col md={12}>
              <label id="description">
                Description <span className="star">*</span>
              </label>
              <textarea
                rows={3}
                className="form-control px-3"
                placeholder="Enter home description here"
                type="text"
                name="description"
                value={editData?.description || ""}
                onChange={handleChange}
                // onKeyPress={handleKeyPress}
              />
              <SingleError error={errors?.description} />
            </Col>

            <Col xs={12} lg={12} xl={12} className="pt-2 cst-card" id="home_page_image">
                            {Boolean(editData?.home_page_image?.length) &&
                                <div className="preview-images mb-4 p-3 gap-3">
                                    {editData?.home_page_image?.map((image, index) => (
                                        <div key={index} className="home-preview">
                                            <img src={image?.name ? URL.createObjectURL(image) : imageURL + image?.file_name} alt={`Selected Preview image ${index}`} />

                                            {/* <img src={URL?.createObjectURL(image)} alt={`Selected Preview image ${index}`} className="home-image" /> */}
                                            <button onClick={() => removeSelectedImages(index, image?.file_name ? image?._id : '')} className="d-flex justify-content-center">
                                                <img src="/images/svg/closeImg.svg" style={{ width: '8px', height: '16px' }} />
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            }
                            <div className="upload__btn d-flex align-items-center justify-content-center py-2">
                                <label className="d-flex align-items-center gap-1">
                                    {!editData?.home_page_image?.length && <span>+</span>}
                                    <p className="mb-0" style={{ color: Boolean(editData?.home_page_image?.length) && "#000" }}>
                                        {Boolean(editData?.home_page_image?.length)
                                            ? editData?.home_page_image?.length + " Images selected"
                                            : "Upload Images"}
                                    </p>
                                    <input
                                        autoComplete="off"
                                        multiple
                                        value={""}
                                        type="file"
                                        // data-max_length="20"
                                        className="upload_inputfile"
                                        name="home_page_image"
                                        onChange={handleImagesChange}
                                    />
                                </label>
                            </div>

                            {Boolean(errors?.home_page_image) &&
                                <SingleError error={errors?.home_page_image} mb={false} />
                            }
                        </Col>
          </Row>
          <div className="d-flex  justify-content-center mt-3">
            <button
              className={`btn-login bg-main text-uppercase border-0 w-75 my-4 ${
                Boolean(loading) && "btnDisable"
              }`}
              disabled={Boolean(loading)}
              onClick={() => {
                editHomeMange();
                setSubmitCount(1);
              }}
            >
              {Boolean(loading) ? "Loading..." : "Save"}
            </button>
          </div>
        </Modal.Body>
      </Modal>

      {/* Delete modal */}
      <Modal
        size="md"
        show={deleteModel}
        onHide={deleteModelCloseFun}
        centered
        className="modal-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pt-4 px-4"
          closeButton
        >
          <Modal.Title className="text-center">
            Delete Home Management{" "}
          </Modal.Title>
          <div className="d-flex justify-content-center text-center mt-2">
            <img src="/images/svg/line.svg" />
          </div>
        </Modal.Header>
        <Modal.Body className="mx-4 border-0">
          <p className="fs-28  fw-500">Are you sure you want to delete?</p>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <div className="d-flex m-auto gap-3 pb-3 px-4">
            <button
              className="modalDeleteBtn px-5 border-0"
              onClick={() => deleteHomeMange()}
            >
              Delete
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default HomeManage;
