export const ValidateFields = (values) => {
  let errors = {};
  // Validate email field
  if (!values?.email) {
    errors.email = "Email field is required.";
  } else if (!/\S+@\S+\.\S+/.test(values?.email)) {
    errors.email = "Invalid email format. Please provide a valid email address.";
  }

  // Validate name field
  if (!values?.name) {
    errors.name = "Name field is required.";
  } else if (values?.name?.trim()?.length === 0) {
    errors.name = "Name cannot be empty or contain only spaces.";
  } else if (values?.name?.length < 2) {
    errors.name = "Name should be minimum 2 characters.";
  }

  // Validate full_name field
  if (!values?.first_name) {
    errors.first_name = "First name field is required.";
  } else if (values?.first_name?.trim()?.length === 0) {
    errors.first_name = "First name cannot be empty or contain only spaces.";
  }


  // Validate full_name field
  if (!values?.last_name) {
    errors.last_name = "Last name field is required.";
  } else if (values?.last_name?.trim()?.length === 0) {
    errors.last_name = "Last name cannot be empty or contain only spaces.";
  }

  // Validate contact_no field
  if (!values?.contact_no) {
    errors.contact_no = "Contact number field is required.";
  } else if (!/^\+?\d+$/.test(values?.contact_no)) {
    errors.contact_no = "Please enter a valid contact number.";
  }

  // Validate password field
  if (!values?.password) {
    errors.password = "Password field is required.";
  } else if (values?.password?.length < 3) {
    errors.password = "Password must be at least 3 characters long";
  }

  // Validate title field
  if (!values?.title) {
    errors.title = "Title field is required.";
  } else if (values?.title?.trim()?.length === 0) {
    errors.title = "Title cannot be empty or contain only spaces.";
  } else if (values?.title?.length < 2) {
    errors.title = "Title should be minimum 2 characters.";
  }

  // Validate description field
  if (!values?.description) {
    errors.description = "Description field is required.";
  } else if (values?.description?.trim()?.length === 0) {
    errors.description = "Description cannot be empty or contain only spaces.";
  } else if (values?.description?.trim()?.length < 2) {
    errors.description = "Description should be minimum 2 characters.";
  }

  // Validate lecture index field
  if (!values?.lecture_index) {
    errors.lecture_index = "Please select a lecture index from the options.";
  }

  // Validate lecture id field
  if (!values?.lecture_id) {
    errors.lecture_id = "Please select a lecture from the options.";
  }

  // Validate chapter index field
  if (!values?.chapter_index) {
    errors.chapter_index = "Please select a chapter index from the options.";
  }

  // // Validate time duration field
  // if (!values?.duration) {
  //   errors.duration = "Time Duration field is required.";
  // } else if (!Boolean(/[a-zA-Z]/.test(values?.duration) && /\d/.test(values?.duration))) {
  //   errors.duration = "Invalid time duration format. Please enter a valid duration (e.g., 10 min).";
  // }




  // Validate template type field
  if (!values?.template_type) {
    errors.template_type = "Please select a template type from the options.";
  }

  // Validate content field
  if (!values?.content) {
    errors.content = "Content field is required.";
  }
  if (!values?.gender) {
    errors.gender = "Gender field is required.";
  }
  if (!values?.duration) {
    errors.duration = "Duration field is required.";
  } else if (values.duration <= 0) {
    errors.duration = "Duration must be a positive number.";
  }
  if (!values?.chapter) {
    errors.chapter = "Week field is required.";
  } else if (values.chapter <= 0) {
    errors.chapter = "Week must be a positive number.";
  }
  if (!values?.day) {
    errors.day = "Day field is required.";
  } else if (values.day <= 0) {
    errors.day = "Day must be a positive number.";
  }

  // Validate type field
  if (!values?.type) {
    errors.type = "Please select type.";
  }
  if (!values?.category) {
    errors.category = "Please select category.";
  }
  if (!values?.subType) {
    errors.subType = "Please select sub type.";
  }
  if (!values?.subscriptionStatus) {
    errors.subscriptionStatus = "Please select subscription status.";
  }
  if (!Boolean(values?.show_sub_category?.toString()==="false" || values?.show_sub_category?.toString()==="true")) {
    errors.show_sub_category = "Please select show sub category true or false.";
  }

  // videoUrl field
  if (!(values?.videoUrl)) {
    errors.videoUrl = "Please enter video url";
  }
  else if (!Boolean(values?.videoUrl?.startsWith('http://') || values?.videoUrl?.startsWith('https://'))) {
    errors.videoUrl = "The input does not have a valid link format. Please enter a valid video URL."
  }

  // Validate lecture image
  if (!values?.lecture_image) {
    errors.lecture_image = "Please select an lecture image";
  }
  if (!values?.image) {
    errors.image = "Please select image";
  }


  // Validate home images field
  if (!Boolean(values?.home_page_image?.length)) {
    errors.home_page_image = "Please select an home images";
  }
  else if (values?.home_page_image?.length < 1) {
    errors.home_page_image = "Please select at least one image before proceeding.";
  }

  if (!values?.recipes_images) {
    errors.recipes_images = "Please select an recipe image";
  }

  if (!values?.best_for_you_image) {
    errors.best_for_you_image = "Please select an best for you image";
  }
  if (!values?.review_image) {
    errors.review_image = "Please select an review image";
  }

  if (!values?.date) {
    errors.date = "Please select a date";
  }

  return errors;
};
